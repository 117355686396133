import React from 'react';
import logo from '../../logo.svg';
import '../../App.css';
import Header from '../../common_components/Header';
import api_route from '../../common_components/api_route';
import Header2 from '../../common_components/Header2';
import { checkLogin } from '../../common_components/helper';

const $ = require('jquery');

$.Datatable = require('datatables.net')
class Biodata extends React.Component{
    
    constructor(props){
		super(props);

		this.state = {
            nama: '',
            data: null
		}

    }

    componentDidMount(){
        
        if(localStorage.getItem('key') != null && localStorage.getItem('key') != ''){
            $.ajax({
                url: api_route.ceklogin+'/'+localStorage.getItem('key')+'/'+localStorage.getItem('username'),
                type: 'GET',
                success: (response) => {
                console.log('cek login ',response)
                if(response.login_info.status == 'OK'){
                    $.ajax({
                        url: api_route.biodata+'/'+localStorage.getItem('key')+'/'+localStorage.getItem('id_user'),
                        type: 'GET',
                        success: (response) => {
                        this.setState({
                            data : response.data[0]
                        })
                        },
                        error: (err, response) => {
                        alert('connection error');
                        if(err.responseJSON){
                            window.location.href = window.location.href.split('#')[0] + '#/';
                        }
                        }
                    });
                }else{
                    localStorage.clear();
                    sessionStorage.clear();
                    // location.reload();
                    window.location.href = "/";
                }
        
                },
                error: (err, response) => {
                //   alert('connection error');
                if(err.responseJSON){
                    alert(err.responseJSON.login_info.msg)
                    // window.location.href = window.location.href.split('#')[0] + '#/';
                }
                }
            });
        }else{
            localStorage.clear();
            sessionStorage.clear();
            // location.reload();
            window.location.href = "/";

        }
    }

    render() {
        let data = this.state.data;
        
        return (
            
            <div className="App about">
                <Header role="2" />
                <div className="container pb-4" >
                    <div className="section-title pb-0 pt-4">
                        <h2>Biodata</h2>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-12">
                            <div className="row" id="regulation">
                                <div className="col-lg-12 col-md-12 section-header">
                                    <div id="accordion" className="accordion">
                                        <div style={{textAlign:'center'}}>
                                                <div className="row form">
                                                    <div className="col-md-3" style={{textAlign:'left'}}>Nama</div>
                                                    <div className="col-md-1 show_pdf">:</div>
                                                    <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.nama}</div>
                                                </div>
                                                <hr />
                                                <div className="row form">
                                                    <div className="col-md-3" style={{textAlign:'left'}}>Jenis Identitas</div>
                                                    <div className="col-md-1 show_pdf">:</div>
                                                    <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.id_identitas == 1 ? "KTP" : (data && data.id_identitas == 2 ? "SIM": "PASPOR" )}</div>
                                                </div>
                                                <hr />
                                                <div className="row form">
                                                    <div className="col-md-3" style={{textAlign:'left'}}>No Identitas</div>
                                                    <div className="col-md-1 show_pdf">:</div>
                                                    <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.no_identitas}</div>
                                                </div>
                                                <hr />
                                                <div className="row form">
                                                    <div className="col-md-3" style={{textAlign:'left'}}>Pekerjaan</div>
                                                    <div className="col-md-1 show_pdf">:</div>
                                                    <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.pekerjaan}</div>
                                                </div>
                                                <hr />
                                                <div className="row form">
                                                    <div className="col-md-3" style={{textAlign:'left'}}>Email</div>
                                                    <div className="col-md-1 show_pdf">:</div>
                                                    <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.email}</div>
                                                </div>
                                                <hr />
                                                <div className="row form">
                                                    <div className="col-md-3" style={{textAlign:'left'}}>No Telepon</div>
                                                    <div className="col-md-1 show_pdf">:</div>
                                                    <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.no_telp}</div>
                                                </div>
                                                <hr />
                                                <div className="row form">
                                                    <div className="col-md-3" style={{textAlign:'left'}}>Status User</div>
                                                    <div className="col-md-1 show_pdf">:</div>
                                                    <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.status == 1 ? "AKTIF" : "TIDAK AKTIF"}</div>
                                                </div>
                                                <hr />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}
export default Biodata;
