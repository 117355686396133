import React from 'react';
import logo from '../logo.svg';
import '../App.css';
import Header from '../common_components/Header';
import api_route from '../common_components/api_route';
import {CryptoJSAesDecrypt} from '../common_components/helper';
import { Bar, Line, Pie, HorizontalBar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';


const $ = require('jquery');
ChartJS.register(
  ...registerables,
  ChartDataLabels
)

$.Datatable = require('datatables.net')
class Survey extends React.Component{

    postSurvey(username, feedback){
      let formDataParam = new FormData();
      formDataParam.append('username', username)
      formDataParam.append('feedback', feedback)
      $.ajax({
          url: api_route.survey,
          data: formDataParam,
          processData: false,
          contentType:false,
          type: 'POST',
          success: (response) => {
              this.setState({
                      survey : JSON.parse(CryptoJSAesDecrypt(response.data))
                    })
          //   console.log(response.data)
          //   console.log("dec", JSON.parse(CryptoJSAesDecrypt(response.data)))
          //   console.log("state", this.state.data)
          //   console.log("length ",this.state.data.length)
          },
          error: (err, response) => {
            alert('connection error');
            if(err.responseJSON){
              window.location.href = window.location.href.split('#')[0] + '#/';
            }
          }
      });
    }

    state = {
        isFeedBack: true,
      }
    
      onChangeResponse = (e) => {
        console.log(e.currentTarget.value)
        this.setState({isFeedBack: false})
        this.postSurvey(localStorage.getItem('username'), e.currentTarget.value)

      }
    
      originPage = () => {
        const {resources} = this.props
        const {emojis} = resources
        const {isVerified} = this.props
        
        return (
          <div className="emojis-container">
            <h1>
              Apakah anda puas dengan 
              <br></br>
              pelayanan informasi kami?
            </h1>
            <br></br>
            <ul className="emoji-container">
              {emojis.map(emoji => (
                <li key={emoji.id} className="list-container">
                  {(isVerified == true) ?
                    <button
                    type="button"
                    value={emoji.name}
                    onClick={this.onChangeResponse}
                    className="button"
                  >
                    <img src={emoji.imageUrl} alt={emoji.name} className="img" />
                    <p>{emoji.name}</p>
                  </button> 
                  :<button
                  type="button"
                  data-toggle="modal" href="#exampleModal" style={{cursor: 'pointer'}}
                  className="button"
                >
                  <img src={emoji.imageUrl} alt={emoji.name} className="img" />
                  <p>{emoji.name}</p>
                </button>
                }
                  
                </li>
              ))}
            </ul>
          </div>
        )
      }
    
      feedBackPage = () => {
        const {resources} = this.props
        return (
          <div className="tq-container">
            <h1>Terima Kasih</h1>
            <p>
              Kami akan gunakan masukan anda untuk memperbaiki 
              <br />
              performa sistem kami.
            </p>
          </div>
        )
      }
      
    
      render() {
        const {isFeedBack} = this.state
        return (
          <div id="survey" className='statis'>
            <div className="bg-container">
                <div className="sub-container">
                {isFeedBack ? this.originPage() : this.feedBackPage()}
                </div>
            </div>
          </div>
          
        )
      }
}
export default Survey;
