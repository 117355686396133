import React from 'react';
import logo from '../../logo.svg';
import '../../App.css';
import Header from '../../common_components/Header';
import api_route from '../../common_components/api_route';
import Header2 from '../../common_components/Header2';
import { checkLogin } from '../../common_components/helper';
import Survey from '../Survey';

const $ = require('jquery');

$.Datatable = require('datatables.net')
class History extends React.Component{
    
    constructor(props){
		super(props);

		this.state = {
            nama: '',
            data: null
		}

    }

    componentDidMount(){
        
        if(localStorage.getItem('key') != null && localStorage.getItem('key') != ''){
            $.ajax({
                url: api_route.ceklogin+'/'+localStorage.getItem('key')+'/'+localStorage.getItem('username'),
                type: 'GET',
                success: (response) => {
                console.log('cek login ',response)
                if(response.login_info.status == 'OK'){
                    $.ajax({
                        url: api_route.history+'/'+localStorage.getItem('username'),
                        type: 'GET',
                        success: (response) => {
                          this.setState({
                            data : response.data
                          })
                        },
                        error: (err, response) => {
                          alert('connection error');
                          if(err.responseJSON){
                              window.location.href = window.location.href.split('#')[0] + '#/';
                          }
                        }
                    });
                }else{
                    localStorage.clear();
                    sessionStorage.clear();
                    // location.reload();
                    window.location.href = "/";
                }
        
                },
                error: (err, response) => {
                //   alert('connection error');
                if(err.responseJSON){
                    alert(err.responseJSON.login_info.msg)
                    // window.location.href = window.location.href.split('#')[0] + '#/';
                }
                }
            });
        }else{
            localStorage.clear();
            sessionStorage.clear();
            // location.reload();
            window.location.href = "/";

        }
		
    }

    render() {
        const resources = {
            emojis: [
              {
                id: 0,
                name: 'Kurang Puas',
                imageUrl: 'https://assets.ccbp.in/frontend/react-js/sad-emoji-img.png',
              },
              {
                id: 1,
                name: 'Puas',
                imageUrl: 'https://assets.ccbp.in/frontend/react-js/none-emoji-img.png',
              },
              {
                id: 2,
                name: 'Sangat Puas',
                imageUrl: 'https://assets.ccbp.in/frontend/react-js/happy-emoji-img.png',
              },
            ],
            loveEmojiUrl: 'https://assets.ccbp.in/frontend/react-js/love-emoji-img.png',
          }
        let data = this.state.data;
        let permohonan_list = [];
        let keberatan_list = [];
		if(data && data.permohonan && data.permohonan.length > 0){
			data.permohonan.map((value, index) => {
                permohonan_list.push(
                    <tr key={index}>
                        <td>{index+1}</td>
                        <td>{value.judul}</td>
                        <td>{value.rincian}</td>
                        <td>{value.tujuan}</td>
                        <td>{value.updstamp}</td>
                        <td>{value.status_tinjut == 1 ? "BELUM TINJUT" : (value.status_tinjut == 2 ? "SUDAH TINJUT": "DITOLAK" )}</td>
                        <td>{value.balasan_tinjut}</td>
                    </tr>

                );
            });
		}
		if(data && data.keberatan && data.keberatan.length > 0){
			data.keberatan.map((value, index) => {
                keberatan_list.push(
                    <tr key={index}>
                        <td>{index+1}</td>
                        <td>{value.judul}</td>
                        <td>{value.rincian_keberatan}</td>
                        <td>{value.updstamp}</td>
                        <td>{value.status_tinjut == 1 ? "BELUM TINJUT" : (value.status_tinjut == 2 ? "SUDAH TINJUT": "DITOLAK" )}</td>
                        <td>{value.balasan_keberatan}</td>
                    </tr>

                );
            });
		}
        return (
            
            <div className="App">
                <Header role="2" />
                <div className="about">
                    <div className="container pb-4" >
                        <div className="section-title pb-0 pt-4">
                            <h2>History User</h2>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12">
                                <div className="row" id="regulation">
                                    <div className="col-lg-12 col-md-12 section-header">
                                        <div id="accordion" className="accordion">
                                            <div className="table-responsive" style={{textAlign:'center'}}>
                                                <table id="tb_regulasi" className="table table-striped table-bordered" style={{textAlign:'left', margin:'auto'}}>
                                                    <thead style={{backgroundColor:'#006eae', color:'white'}}>
                                                        <tr>
                                                            <th style={{textAlign:'center'}}>Permohonan Informasi</th>
                                                            <th style={{textAlign:'center'}}>Permohonan Keberatan</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>BELUM TINJUT : {data && data.permohonan_belum}</td>
                                                            <td>BELUM TINJUT : {data && data.keberatan_belum}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>SUDAH TINJUT : {data && data.permohonan_sudah}</td>
                                                            <td>SUDAH TINJUT : {data && data.keberatan_sudah}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>TOLAK : {data && data.permohonan_tolak}</td>
                                                            <td>TOLAK : {data && data.keberatan_tolak}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about">
                    <div className="container pb-4" >
                        <div className="section-title pb-0 pt-4">
                            <h2>Permohonan Informasi</h2>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12">
                                <div className="row" id="regulation">
                                    <div className="col-lg-12 col-md-12 section-header">
                                        <div id="accordion" className="accordion">
                                            <div className="table-responsive" style={{textAlign:'center'}}>
                                                <table id="tb_regulasi" className="table table-striped table-bordered dataTables-basic" style={{textAlign:'left', margin:'auto'}}>
                                                    <thead style={{backgroundColor:'#006eae', color:'white'}}>
                                                        <tr>
                                                            <th style={{textAlign:'center'}}>No</th>
                                                            <th style={{textAlign:'center'}}>Judul</th>
                                                            <th style={{textAlign:'center'}}>Rincian</th>
                                                            <th style={{textAlign:'center'}}>Tujuan</th>
                                                            <th style={{textAlign:'center'}}>Tanggal Permohonan</th>
                                                            <th style={{textAlign:'center'}}>Status</th>
                                                            <th style={{textAlign:'center'}}>Keterangan</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {permohonan_list}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="about">
                    <div className="container pb-4" >
                        <div className="section-title pb-0 pt-4">
                            <h2>Pengajuan Keberatan</h2>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12">
                                <div className="row" id="regulation">
                                    <div className="col-lg-12 col-md-12 section-header">
                                        <div id="accordion" className="accordion"><div className="table-responsive" style={{textAlign:'center'}}>
                                            <table id="tb_regulasi" className="table table-striped table-bordered dataTables-basic" style={{textAlign:'left', margin:'auto'}}>
                                                <thead style={{backgroundColor:'#006eae', color:'white'}}>
                                                    <tr>
                                                        <th style={{textAlign:'center'}}>No</th>
                                                        <th style={{textAlign:'center'}}>Judul</th>
                                                        <th style={{textAlign:'center'}}>Rincian</th>
                                                        <th style={{textAlign:'center'}}>Tanggal Permohonan</th>
                                                        <th style={{textAlign:'center'}}>Status</th>
                                                        <th style={{textAlign:'center'}}>Keterangan</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {keberatan_list}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="about">
                    <div className="container pb-4" >
                        <div className="section-title pb-0 pt-4">
                            <h2>Pengajuan Keberatan</h2>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12">
                                <div className="row" id="regulation">
                                    <div className="col-lg-12 col-md-12 section-header">
                                        <div id="accordion" className="accordion"><div className="table-responsive" style={{textAlign:'center'}}>
                                            <table id="tb_regulasi" className="table table-striped table-bordered dataTables-basic" style={{textAlign:'left', margin:'auto'}}>
                                                <thead style={{backgroundColor:'#006eae', color:'white'}}>
                                                    <tr>
                                                        <th style={{textAlign:'center'}}>No</th>
                                                        <th style={{textAlign:'center'}}>Judul</th>
                                                        <th style={{textAlign:'center'}}>Rincian</th>
                                                        <th style={{textAlign:'center'}}>Tanggal Permohonan</th>
                                                        <th style={{textAlign:'center'}}>Status</th>
                                                        <th style={{textAlign:'center'}}>Keterangan</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {keberatan_list}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                
                {/* <div className="about">
                    <div className="container pb-4" >
                        <div className="section-title pb-0 pt-4">
                            <h2>Survey Kepuasan Layanan Informasi Publik</h2>
                        </div>
                        <Survey resources={resources}/>
                    </div>
                </div> */}
            </div>
        </div>
        );
    }
}
export default History;
