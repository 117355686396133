import React from 'react';
import logo from '../../logo.svg';
import '../../App.css';
import Header from '../../common_components/Header';
import api_route from '../../common_components/api_route';
import Header2 from '../../common_components/Header2';
import { checkLogin } from '../../common_components/helper';

const $ = require('jquery');

$.Datatable = require('datatables.net')
class Permohonan extends React.Component{
    
    constructor(props){
		super(props);

		this.state = {
            nama: '',
            data: null,
            pengajuan : null,
            cara_info : null
		}

        this.permohonan = this.permohonan.bind(this);
    }

    componentDidMount(){
        
        
        if(localStorage.getItem('key') != null && localStorage.getItem('key') != ''){
            $.ajax({
                url: api_route.ceklogin+'/'+localStorage.getItem('key')+'/'+localStorage.getItem('username'),
                type: 'GET',
                success: (response) => {
                console.log('cek login ',response)
                if(response.login_info.status == 'OK'){
                    $.ajax({
                        url: api_route.biodata+'/'+localStorage.getItem('key')+'/'+localStorage.getItem('id_user'),
                        type: 'GET',
                        success: (response) => {
                          this.setState({
                            data : response.data[0]
                          })
                        },
                        error: (err, response) => {
                          if(err.responseJSON){
                              window.location.href = window.location.href.split('#')[0] + '#/';
                          }
                        }
                    });
                    $.ajax({
                        url: api_route.cara_info,
                        type: 'GET',
                        success: (response) => {
                          this.setState({
                            cara_info : response.data
                          })
                        },
                        error: (err, response) => {
                          if(err.responseJSON){
                              window.location.href = window.location.href.split('#')[0] + '#/';
                          }
                        }
                    });
                }else{
                    localStorage.clear();
                    sessionStorage.clear();
                    // location.reload();
                    window.location.href = "/";
                }
        
                },
                error: (err, response) => {
                //   alert('connection error');
                if(err.responseJSON){
                    alert(err.responseJSON.login_info.msg)
                    // window.location.href = window.location.href.split('#')[0] + '#/';
                }
                }
            });
        }else{
            localStorage.clear();
            sessionStorage.clear();
            // location.reload();
            window.location.href = "/";

        }
		
    }

    permohonan = (e) => {
        e.preventDefault();
        var judul = document.getElementById('judul').value;
        var rincian = document.getElementById('rincian').value;
        var tujuan = document.getElementById('tujuan').value;
        var cara_info = document.getElementById('cara_info').value;
        
        if((judul.trim()).length > 0){
            if((rincian.trim()).length > 0){
                if((tujuan.trim()).length > 0){
                    if((cara_info.trim()).length > 0){
                        let formDataParam = new FormData();
                        formDataParam.append('judul', judul)
                        formDataParam.append('rincian', rincian)
                        formDataParam.append('tujuan', tujuan)
                        formDataParam.append('id_cara_info', cara_info)
                        $.ajax({
                            url: api_route.permohonan_informasi+'/'+localStorage.getItem('username'),
                            data: formDataParam,
                            processData: false,
                            contentType: false,
                            type: 'POST',
                            success: (response) => {
                                alert(response.permohonan_info.msg);
                                window.location.href = '/history';

                            },
                            error: (err, response) => {
                                alert(response.permohonan_info.msg)
                            if(err.responseJSON){
                                window.location.href = window.location.href.split('#')[0] + '#/';
                            }
                            }
                        });
                    }else{
                      alert('Cara Info tidak boleh kosong');
                    }
                }else{
                  alert('Tujuan tidak boleh kosong');
                }
            }else{
              alert('Rincian tidak boleh kosong');
            }
        }else{
          alert('Judul tidak boleh kosong');
        }
    }
    render() {
        let data = this.state.data;
        let pengajuan = this.state.pengajuan;
        let pengajuan_list = [];
        console.log(pengajuan)
		if(pengajuan && pengajuan.length > 0){
            console.log('pengajuan')

			pengajuan.map((value, index) => {
                pengajuan_list.push(
                    <option value={value.id_pengajuan} key={index}>{value.judul}</option>
                );
            });
        }
        let cara_info = this.state.cara_info;
        let cara_info_list = [];
        console.log(cara_info)
		if(cara_info && cara_info.length > 0){
            console.log('cara_info')

			cara_info.map((value, index) => {
                cara_info_list.push(
                    <option value={value.id_cara_info} key={index}>{value.cara_info}</option>
                );
            });
        }
        console.log(cara_info_list)
        return (
            
            <div className="App">
                <Header role="2" />
                <div className="about">
                    <div className="container pb-4" >
                        <div className="section-title pb-0 pt-4">
                            <h2>Permohonan Informasi</h2>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12">
                                <div className="row" id="regulation">
                                    <div className="col-lg-12 col-md-12 section-header">
                                        <div id="accordion" className="accordion">
                                            <div className="col-12">
                                                <div className="" style={{textAlign:'center'}}>
                                                    <div className="row form">
                                                        <div className="col-md-3" style={{textAlign:'left'}}>Nama</div>
                                                        <div className="col-md-1 show_pdf">:</div>
                                                        <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.nama}</div>
                                                    </div>
                                                    <hr />
                                                    <div className="row form">
                                                        <div className="col-md-3" style={{textAlign:'left'}}>Jenis Identitas</div>
                                                        <div className="col-md-1 show_pdf">:</div>
                                                        <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.id_identitas == 1 ? "KTP" : (data && data.id_identitas == 2 ? "SIM": "PASPOR" )}</div>
                                                    </div>
                                                    <hr />
                                                    <div className="row form">
                                                        <div className="col-md-3" style={{textAlign:'left'}}>No Identitas</div>
                                                        <div className="col-md-1 show_pdf">:</div>
                                                        <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.no_identitas}</div>
                                                    </div>
                                                    <hr />
                                                    <div className="row form">
                                                        <div className="col-md-3" style={{textAlign:'left'}}>Pekerjaan</div>
                                                        <div className="col-md-1 show_pdf">:</div>
                                                        <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.pekerjaan}</div>
                                                    </div>
                                                    <hr />
                                                    <div className="row form">
                                                        <div className="col-md-3" style={{textAlign:'left'}}>Email</div>
                                                        <div className="col-md-1 show_pdf">:</div>
                                                        <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.email}</div>
                                                    </div>
                                                    <hr />
                                                    <div className="row form">
                                                        <div className="col-md-3" style={{textAlign:'left'}}>No Telepon</div>
                                                        <div className="col-md-1 show_pdf">:</div>
                                                        <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.no_telp}</div>
                                                    </div>
                                                    <hr />
                                                    <div className="row form">
                                                        <div className="col-md-3" style={{textAlign:'left'}}>Status User</div>
                                                        <div className="col-md-1 show_pdf">:</div>
                                                        <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.status == 1 ? "AKTIF" : "TIDAK AKTIF"}</div>
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about">
                    <div className="container pb-4" >
                        <div className="section-title pb-0 pt-4">
                            <h2>Informasi</h2>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12">
                                <div className="row" id="regulation">
                                    <div className="col-lg-12 col-md-12 section-header">
                                        <div id="accordion" className="accordion">
                                            
                                            <form className="form" onSubmit={this.permohonan.bind(this)} style={{textAlign:'center'}}>
                                                <div className="row form">
                                                    <div className="col-md-3" style={{textAlign:'left'}}>Judul*</div>
                                                    <div className="col-md-1 show_pdf">:</div>
                                                    <div className="col-md-8 show_pdf" style={{textAlign:'left'}}><input type="text" className="form-control" placeholder="Judul" name="judul"  id="judul" required /></div>
                                                </div>
                                                <hr />
                                                <div className="row form">
                                                    <div className="col-md-3" style={{textAlign:'left'}}>Rincian Informasi*</div>
                                                    <div className="col-md-1 show_pdf">:</div>
                                                    <div className="col-md-8 show_pdf" style={{textAlign:'left'}}><textarea className="form-control" placeholder="Rincian Informasi" name="rincian" id="rincian" required /></div>
                                                </div>
                                                <hr />
                                                <div className="row form">
                                                    <div className="col-md-3" style={{textAlign:'left'}}>Tujuan Informasi*</div>
                                                    <div className="col-md-1 show_pdf">:</div>
                                                    <div className="col-md-8 show_pdf" style={{textAlign:'left'}}><textarea className="form-control" placeholder="Tujuan" name="tujuan" id="tujuan" required /></div>
                                                </div>
                                                <hr />
                                                <div className="row form">
                                                    <div className="col-md-3" style={{textAlign:'left'}}>Cara Mendapatkan Informasi*</div>
                                                    <div className="col-md-1 show_pdf">:</div>
                                                    <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>
                                                        <select className="form-control" id="cara_info" name="cara_info" required>
                                                            {cara_info_list}
                                                        </select>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="form" style={{textAlign:'center'}}>
                                                    <div className="form-actions">
                                                        <div className="text-right">
                                                            <button type="submit" className="btn btn-info">Kirim</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
        );
    }
}
export default Permohonan;
