import React from 'react';
import logo from '../logo.svg';
import '../App.css';
import Header from '../common_components/Header';
import api_route from '../common_components/api_route';
import {CryptoJSAesDecrypt} from '../common_components/helper';

const $ = require('jquery');

$.Datatable = require('datatables.net')
class Faq extends React.Component{
    
    constructor(props){
		super(props);

		this.state = {
            nama: '',
            data: null
		}

    }

    componentDidMount(){
        
		$.ajax({
            url: api_route.faq,
            type: 'GET',
            success: (response) => {
                // console.log(response)
			  this.setState({
                data : JSON.parse(CryptoJSAesDecrypt(response.data))
              })
            //   console.log("dec", JSON.parse(CryptoJSAesDecrypt(response.data)))
            //   console.log("state", this.state.data)
            //   console.log("length ",this.state.data.length)
            },
            error: (err, response) => {
              alert('connection error');
              if(err.responseJSON){
              	window.location.href = window.location.href.split('#')[0] + '#/';
              }
            }
        });
    }

    render() {
        let data = this.state.data;
        let faq_list = [];
		if(data != null && data.length > 0){
			data.map((value, index) => {
                faq_list.push(
                    <div className="col-lg-6 faq-item" data-aos="fade-up" key={index}>
                        <h4>{value.judul}</h4>
                        <p>
                            {value.keterangan}
                        </p>
                    </div>
                );
            });
		}
        return (
            <section id="faq" className="faq">
                <div className="container">

                    <div className="section-title">
                    <h2>Frequently Asked Questions</h2>
                    </div>

                    <div className="row  d-flex align-items-stretch">

                    
                        {faq_list}


                    </div>

                </div>
            </section>
        );
    }
}
export default Faq;
