import React from 'react';
import logo from '../logo.svg';
import '../App.css';
import api_route from './api_route';
import {CryptoJSAesDecrypt, getAllUrlParams} from '../common_components/helper';
const $ = window.$;
class Header extends React.Component{
    
    constructor(props){
		super(props);
      this.state = {
        nama: '',
        isVerified: false,
        passicon1 : 'bx bx-show',
        passicon1_status : false,
      }
      this.login = this.login.bind(this);
      this.logout = this.logout.bind(this);
      this.lostpass = this.lostpass.bind(this);
      this.showForget = this.showForget.bind(this);
      this.jumpTo = this.jumpTo.bind(this);
      this.showPass = this.showPass.bind(this);
    }
      jumpTo(section){
        // console.log("Aaaaa")
        const el = document.querySelector("#"+section);
        const y = el.getBoundingClientRect().top + window.pageYOffset - 75;
        window.scrollTo({top: y, behavior: 'smooth'});
      }
    componentDidMount(){
        
		// $.ajax({
        //     url: 'https://172.16.56.65:5000/dapem',
        //     // headers: {
		//     //     'Authorization':'JWT '+sessionStorage.getItem('token')
		//     // },
		//     // data: {
		// 	// 	'period' : param
		// 	// },
        //     // processData: false,
        //     // contentType: false,
        //     type: 'GET',
        //     success: (response) => {
		// 	 	alert(response)
			  
        if(localStorage.getItem('key') != null){
          this.setState({
              isVerified: true
          })
        }
        //     },
        //     error: (err, response) => {
		// 		// // debugger;
        //       alert('connection error');
        //       if(err.responseJSON){
        //       	window.location.href = window.location.href.split('#')[0] + '#/';
        //       }
        //     }
        // });
    }
    login() {
        
      var user =document.getElementById('username').value;
      var pass =document.getElementById('pass').value;
      var otp = this.state.otp;
      // if(user == "" || pass == ""){
      // 	this.setState({status: "mandatory"});
      // 	alert('connection error');
      // }else{
          let formDataParam = new FormData();
          formDataParam.append('username', user);
          formDataParam.append('pass', pass);
          $.ajax({
              url: api_route.login,
              data: formDataParam,
              processData: false,
              contentType: false,
              type: 'POST',
              success: (response) => {
                  if(response.user_info.status == 'OK'){
                      // console.log('value ----- ', response.user_info.data[0]);
                      localStorage.setItem('id_user', response.user_info.data[0].id_user);
                      localStorage.setItem('nama', response.user_info.data[0].nama);
                      localStorage.setItem('username', response.user_info.data[0].username);
                      localStorage.setItem('key', response.user_info.data[0].key);
                      window.location.href = '/home#survey'

                  }else{
                      alert(response.user_info.msg)
                  }
  
              },
              error: (err, response) => {
              //   alert('connection error');
                if(err.responseJSON){
                  alert(err.responseJSON.user_info.msg)
                  // window.location.href = window.location.href.split('#')[0] + '#/';
                }
              }
          });
      }
	  
	logout(e){
    let formDataParam = new FormData();
    formDataParam.append('username', localStorage.getItem('username'));
    formDataParam.append('key', localStorage.getItem('key'));
    $.ajax({
        url: api_route.logout,
        data: formDataParam,
        processData: false,
        contentType: false,
        type: 'POST',
        success: (response) => {
            if(response.user_info.status == 'OK'){
              localStorage.clear();
              sessionStorage.clear();
              // location.reload();
              window.location.href = "/";
            }else{
                alert(response.user_info.msg)
            }
        },
        error: (err, response) => {
        //   alert('connection error');
          if(err.responseJSON){
            alert(err.responseJSON.user_info.msg)
            // window.location.href = window.location.href.split('#')[0] + '#/';
          }
        }
    });
	}
	showForget(){
    $('#exampleModal').modal('hide');
    $('#myModal').modal('show')
  }
  lostpass() {
        
    var email =document.getElementById('email').value;
    let formDataParam = new FormData();
    formDataParam.append('email', email);
    $.ajax({
        url: api_route.lostpass,
        data: formDataParam,
        processData: false,
        contentType: false,
        type: 'POST',
        success: (response) => {
            if(response.user_info.status == 'OK'){
                alert(response.user_info.msg)
            }else{
                alert(response.user_info.msg)
            }
        },
        error: (err, response) => {
        //   alert('connection error');
          if(err.responseJSON){
            alert(err.responseJSON.user_info.msg)
            // window.location.href = window.location.href.split('#')[0] + '#/';
          }
        }
      });
  }
  
  resetpass() {
        
    var pass1 =document.getElementById('password1').value;
    var pass2 =document.getElementById('password2').value;
    var token =getAllUrlParams(window.location.href).token;
    let formDataParam = new FormData();
    formDataParam.append('password1', pass1);
    formDataParam.append('password2', pass2);
    formDataParam.append('token',token);
    $.ajax({
        url: api_route.resetpass,
        data: formDataParam,
        processData: false,
        contentType: false,
        type: 'POST',
        success: (response) => {
          // console.log(response);
            if(response.user_info.status == 'OK'){
                alert(response.user_info.msg)
                localStorage.clear();
                sessionStorage.clear();
                // location.reload();
                window.location.href = "/";
            }else{
                alert(response.user_info.msg)
                localStorage.clear();
                sessionStorage.clear();
                // location.reload();
                window.location.href = "/";
            }
        },
        error: (err, response) => {
        //   alert('connection error');
          if(err.responseJSON){
            alert(err.responseJSON.user_info.msg)
            // window.location.href = window.location.href.split('#')[0] + '#/';
          }
        }
      });
  }
  showPass() {
    // console.log("a")
    if(this.state.passicon1_status == false){
        this.setState({
          passicon1: 'bx bx-hide',
          passicon1_status : true
        })
        document.getElementById('pass').type = 'text';
    }else{
      this.setState({
        passicon1: 'bx bx-show',
        passicon1_status : false
      })
        document.getElementById('pass').type = 'password';
    }
  }
  
  render() {
    return (
      
      <header id="header" class="fixed-top">
        <div className="container">
          <div className="logo float-left">
            <a href=""><img src="/assets/img/logo.png" alt="" className="img-fluid" /></a>
          </div>
          <nav className="nav-menu float-right d-none d-lg-block">
            <ul><li><a href="/" className="active">Beranda</a></li>
              <li><a data-hash="profile" className="main_menu_list" style={{cursor: 'pointer'}}>Profil</a></li>
              <li><a data-hash="regulasi" className="main_menu_list" style={{cursor: 'pointer'}}>Regulasi</a></li>
              <li className="drop-down"><a>Informasi Publik</a>
                <ul className="text-left">
                  <li><a href="/informasi_berkala">Informasi wajib disediakan dan diumumkan secara berkala</a></li>
                  <li><a href="/informasi_serta_merta">Informasi wajib diumumkan secara serta merta</a></li>
                  <li><a href="/informasi_setiap_saat">Informasi wajib tersedia setiap saat</a></li>
                  <li><a href="/informasi_dikecualikan">Informasi Dikecualikan</a></li>
                  <li><a href="/daftar_informasi_publik">Daftar Informasi Publik</a></li>
                  <li><a href="/statistik">Statistik</a></li>
                </ul>
              </li>
              <li className="drop-down"><a>Layanan Informasi</a>
                <ul className="text-left">
                  <li><a href="/prosedur_permohonan_informasi">Permohonan Informasi</a></li>
                  <li><a href="/prosedur_pengajuan_keberatan">Pengajuan Keberatan</a></li>
                  <li><a href="/informasi_laporan">Daftar Pemohon Informasi</a></li>
                  { this.state && this.state.isVerified == true ? <li><a href='/permohonan_informasi' style={{cursor: 'pointer'}}>Permohonan Informasi</a></li> : '' }
                  { this.state && this.state.isVerified == true ? <li><a href='/pengajuan_keberatan' style={{cursor: 'pointer'}}>Pengajuan Keberatan</a></li> : '' }
                </ul>
              </li>
              <li className="drop-down"><a href="">PPID Pelaksana</a>
                <ul className="text-left">
                  <li><a href='/ppid_jakarta' style={{cursor: 'pointer'}}>PPID Cabang Jakarta</a></li>
                  <li><a href='/ppid_bandung' style={{cursor: 'pointer'}}>PPID Cabang Bandung</a></li>
                  <li><a href='/ppid_semarang' style={{cursor: 'pointer'}}>PPID Cabang Semarang</a></li>
                  <li><a href='/ppid_surabaya' style={{cursor: 'pointer'}}>PPID Cabang Surabaya</a></li>
                  <li><a href='/ppid_medan' style={{cursor: 'pointer'}}>PPID Cabang Medan</a></li>
                  <li><a href='/ppid_makassar' style={{cursor: 'pointer'}}>PPID Cabang Makassar</a></li>
                </ul>
              </li>
              <li><a data-hash= 'faq' className="main_menu_list" style={{cursor: 'pointer'}}>FAQ</a></li>
              { this.state && this.state.isVerified == true ? <li><a data-hash= 'survey' className="main_menu_list" style={{cursor: 'pointer'}}>Survey</a></li> : ''}
              {/* <li><a data-hash= 'statistik' className="main_menu_list" style={{cursor: 'pointer'}}>Statistik</a></li> */}
              { this.state && this.state.isVerified == true ? 
                  <li className="drop-down"><a href="">{localStorage.getItem('nama')}</a>
                  <ul className="text-left">
                      <li><a href="/biodata">Biodata</a></li>
                      <li><a href="/history">History Permohonan</a></li>
                  </ul>
                  </li>
              : '' }
              { this.state && this.state.isVerified == true ? 
                  <li><a onClick={this.logout.bind(this)} style={{cursor: 'pointer'}}>Logout</a></li>
              : '' }
              { this.state && this.state.isVerified == true ? '' : <li><a data-toggle="modal" href="#exampleModal" style={{cursor: 'pointer'}}>Login</a></li>}
            </ul>
          </nav>
          <div id="exampleModal" className="modal fade">
            <div className="modal-dialog modal-login">
                <div className="modal-content">
                    <div className="modal-header">		
                        <div className="col-lg-12" style={{textAlign:'center'}}>
                            <img src="/assets/img/logo.png" alt="homepage" className="mb-2" style={{maxWidth:'100%', padding : '0px 10%'}} />
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>						
                    </div>
                    <div className="modal-body">
                        {/* <form > */}
                        <div className="col-lg-12 mb-4" style={{textAlign:'center'}}>
                            <h4 className="modal-title">E-PPID Login</h4>
                        </div>					
                            <div className="form-group">
                                <i className="fa fa-user"></i>
                                <input type="text" className="form-control" name="username"  id="username" placeholder="Username" required="required" />
                            </div>
                            <div className="form-group input-group">
                                <i className="fa fa-lock"></i>
                                <input type="password" className="form-control" name="pass"  id="pass" placeholder="Password" required="required" />	
                                <div className="input-group-append">
                                    <button className="btn btn-outline-secondary" type="button" style={{width:'40px'}} onClick={this.showPass.bind(this)}><i className={this.state.passicon1}></i></button>
                                </div>				
                            </div>
                            <div className="form-group">
                                <input type="button" onClick={this.login.bind(this)} className="btn btn-primary btn-block btn-lg" value="Login" />
                            </div>
                        {/* </form> */}
                    </div>
                    <div className="modal-footer">
                        <a href="/prosedur_permohonan_informasi" style={{cursor: 'pointer'}}>Registrasi</a> |
                        <a onClick={this.showForget.bind(this)} style={{cursor: 'pointer'}}>Lupa Password?</a>
                    </div>
                </div>
            </div>
          </div>   
          
          <div className="modal fade" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">Lupa Password</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="form-group">
                        <i className="fa fa-user"></i>
                        <input type="text" className="form-control" name="email"  id="email" placeholder="Email" required="required" />
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" onClick={this.lostpass.bind(this)}>Kirim Email</button>
                </div>
                </div>
            </div>
          </div>  
          <div className="modal fade" id="reset_password" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">Reset Password</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="form-group input-group">
                        <i className="fa fa-lock"></i>
                        <input type="password" className="form-control" name="password1"  id="password1" placeholder="Password" required="required" />
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" type="button" style={{width:'40px'}} onClick={this.showPass.bind(this)}><i className={this.state.passicon1}></i></button>
                        </div>				
                    </div>
                    <div className="form-group input-group">
                        <i className="fa fa-lock"></i>
                        <input type="password" className="form-control" name="password2"  id="password2" placeholder="Re-type Password" required="required" />
                        <div className="input-group-append">
                            <button className="btn btn-outline-secondary" type="button" style={{width:'40px'}} onClick={this.showPass.bind(this)}><i className={this.state.passicon1}></i></button>
                        </div>				
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" onClick={this.resetpass.bind(this)}>Reset Password</button>
                </div>
                </div>
            </div>
          </div>  
        </div>
      </header>
    );
    }
}
export default Header;