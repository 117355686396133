
import api_route from './api_route';
var CryptoJS = require("crypto-js");
const $ = require('jquery');
export function getAllUrlParams(url) {

  // get query string from url (optional) or window
  var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {

    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    var arr = queryString.split('&');

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split('=');

      // set parameter name and value (use 'true' if empty)
      var paramName = a[0];
      var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

      // (optional) keep case consistent
      paramName = paramName.toLowerCase();
      if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {

        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, '');
        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      } else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === 'string'){
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}


export function text2html(html) {
  var div = document.createElement("div");
  div.innerHTML = html;
  
  div.innerHTML = div.textContent;
  return div;
}


export function decrypt(ciphertext, iv) {
  
      // Mcrypt pads a short key with zero bytes
      var key = CryptoJS.enc.Utf8.parse('Taspen2019Pusat@')
      var plaintext = CryptoJS.AES.decrypt(JSON.stringify({ciphertext}), key, { iv: CryptoJS.enc.Base64.parse(iv), padding: CryptoJS.pad.ZeroPadding })
      var decrypted = CryptoJS.enc.Utf8.stringify(plaintext);
      
      return decrypted;

}

export function CryptoJSAesDecrypt(encrypted_json_string){
  var passphrase = "Taspen2019Pusat@";
  var obj_json = encrypted_json_string;

  var encrypted = obj_json.text;
  var salt = CryptoJS.enc.Hex.parse(obj_json.salt);
  var iv = CryptoJS.enc.Hex.parse(obj_json.iv);   

  var key = CryptoJS.PBKDF2(passphrase, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64/8, iterations: 999});


  var decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv});

  return decrypted.toString(CryptoJS.enc.Utf8);
}


export function checkLogin() {
  var validated = false;
  // Mcrypt pads a short key with zero bytes
  if(localStorage.getItem('key') != null && localStorage.getItem('key') != ''){
    $.ajax({
      url: api_route.ceklogin+'/'+localStorage.getItem('key')+'/'+localStorage.getItem('username'),
      type: 'GET',
      success: (response) => {
        console.log('cek login ',response)
        if(response.login_info.status == 'OK'){

          validated = true;
          console.log('cek validated ',validated)
        }else{
          validated = false;
          console.log('cek validated 1',validated)
        }

      },
      error: (err, response) => {
      //   alert('connection error');
        if(err.responseJSON){
          alert(err.responseJSON.login_info.msg)
          // window.location.href = window.location.href.split('#')[0] + '#/';
        }
      }
    });
  }else{
    validated = false;
  }
  
  console.log('cek validated 2',validated)
  return validated;

}