import React from 'react';
import '../../App.css';
import Header from '../../common_components/Header';
import api_route from '../../common_components/api_route';
import Header2 from '../../common_components/Header2';

const $ = require('jquery');

$.Datatable = require('datatables.net')
class Laporan extends React.Component{
    
    constructor(props){
		super(props);

		this.state = {
            nama: '',
            data: null
		}

    }

    componentDidMount(){
        
		$.ajax({
            url: api_route.list_layanan,
            type: 'GET',
            success: (response) => {
			  this.setState({
                data : response.data
              })
              var t = $('.dataTables-basic').DataTable({
                    "columnDefs": [ {
                        "searchable": false,
                        "orderable": false,
                        "targets": 0
                    } ],
                    "order": [[ 1, 'asc' ]]
                } );
                t.on( 'order.dt search.dt', function () {
                    t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
                        cell.innerHTML = i+1;
                    } );
                } ).draw();
            },
            error: (err, response) => {
              if(err.responseJSON){
              	window.location.href = window.location.href.split('#')[0] + '#/';
              }
            }
        });
    }

    render() {
        let data = this.state.data;
        let laporan_list = [];
		if(data != null && data.length > 0){
			data.map((value, index) => {
                laporan_list.push(
                    <tr key={index}>
                        <td>{}</td>
                        <td>{value.user_created}</td>
                        <td>{value.judul}</td>
                        <td>{value.updstamp}</td>
                        <td>{value.updstamp_balasan}</td>
                        <td>{value.status_tinjut == 1 ? "BELUM TINJUT" : (value.status_tinjut == 2 ? "SUDAH TINJUT": "DITOLAK" )}</td>
                    </tr>

                );
            });
		}
        return (
            <div className="App about">
                <Header role="2" />
                <div className="container pb-4" >
                    <div className="section-title pb-0 pt-4">
                        <h2>Daftar Laporan</h2>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-12">
                            <div className="row" id="regulation">
                                <div className="col-lg-12 col-md-12 section-header">
                                    <div id="accordion" className="accordion">
                                        <div className="table-responsive" style={{textAlign:'center'}}>
                                            <table id="tb_regulasi" className="table table-striped table-bordered dataTables-basic" style={{textAlign:'left', margin:'auto'}}>
                                                <thead style={{backgroundColor:'#006eae', color:'white'}}>
                                                    <tr>
                                                        <th>No</th>
                                                        <th>Nama Pemohon</th>
                                                        <th>Judul</th>
                                                        <th>Tanggal Permohonan</th>
                                                        <th>Tanggal Diselesaikan</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {laporan_list}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Laporan;
