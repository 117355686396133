import React from 'react';
import logo from '../logo.svg';
import '../App.css';
import Header from '../common_components/Header';
import api_route from '../common_components/api_route';
import {CryptoJSAesDecrypt} from '../common_components/helper';
import { Bar, Line, Pie, HorizontalBar } from 'react-chartjs-2';
import { Chart as ChartJS, registerables} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';


const $ = require('jquery');

ChartJS.register(
    ...registerables,
    ChartDataLabels
)

$.Datatable = require('datatables.net')
class Statistik extends React.Component{

    constructor(props){
		super(props);

		this.state = {
            nama: '',
            data: null,
            year: null,
            job: null,
            info: null,
            reqinfo: null,
            allyearly: null,
            approveyearly: null,
            declineyearly: null,
            timeyearly: null,
            stat: null,
            media: null,
            stat_custcare: null,
            rangeyear: null,
            curryear: null
		}

        const range = (start, end) => {
            return new Array(end - start).fill().map((d, i) => (i + start).toString());
          };
        this.rangeyear = range(2020, ((new Date()).getFullYear() + 1));
        this.curryear = this.rangeyear[((new Date()).getFullYear() + 1)-2020-1];

        // const year = (new Date()).getFullYear();
        // this.years = Array.from(new Array(10),(val, index) => index + year);

        // const rangeee = range
        // this.rangeyear = rangeee;

    }

    getYear(){
      $.ajax({
          url: api_route.get_year,
          type: 'GET',
          success: (response) => {
      this.setState({
              year : response.data

            })
          //   console.log("dec", JSON.parse(CryptoJSAesDecrypt(response.data)))
          //   console.log("state", this.state.data)
          //   console.log("length ",this.state.data.length)
          },
          error: (err, response) => {
            alert('connection error');
            if(err.responseJSON){
              window.location.href = window.location.href.split('#')[0] + '#/';
            }
          }
      });
    }

    getStat(){
      $.ajax({
          url: api_route.get_stat,
          type: 'GET',
          success: (response) => {
          this.setState({
            stat : JSON.parse(CryptoJSAesDecrypt(response.data))
            })
            // console.log("dec", JSON.parse(CryptoJSAesDecrypt(response.data)))
          //   console.log("state", this.state.data)
          //   console.log("length ",this.state.data.length)
          },
          error: (err, response) => {
            alert('connection error');
            if(err.responseJSON){
              window.location.href = window.location.href.split('#')[0] + '#/';
            }
          }
      });
    }

    getStatCustCare(){
      $.ajax({
          url: api_route.get_stat_custcare,
          type: 'GET',
          success: (response) => {
          this.setState({
            stat_custcare : JSON.parse(CryptoJSAesDecrypt(response.data))
            })
            // console.log("dec", JSON.parse(CryptoJSAesDecrypt(response.data)))
          //   console.log("state", this.state.data)
          //   console.log("length ",this.state.data.length)
          },
          error: (err, response) => {
            alert('connection error');
            if(err.responseJSON){
              window.location.href = window.location.href.split('#')[0] + '#/';
            }
          }
      });
    }

    getAllYearly(){
      $.ajax({
          url: api_route.get_allyearly,
          type: 'GET',
          success: (response) => {
      this.setState({
              allyearly : JSON.parse(CryptoJSAesDecrypt(response.data))
            })
            // console.log("dec all yearly", JSON.parse(CryptoJSAesDecrypt(response.data)))
          //   console.log("state", this.state.data)
          //   console.log("length ",this.state.data.length)
          },
          error: (err, response) => {
            alert('connection error');
            if(err.responseJSON){
              window.location.href = window.location.href.split('#')[0] + '#/';
            }
          }
      });
    }

    getApproveYearly(){
      $.ajax({
          url: api_route.get_approveyearly,
          type: 'GET',
          success: (response) => {
      this.setState({
              approveyearly : JSON.parse(CryptoJSAesDecrypt(response.data))
            })
            // console.log("dec approve yearly", JSON.parse(CryptoJSAesDecrypt(response.data)))
          //   console.log("state", this.state.data)
          //   console.log("length ",this.state.data.length)
          },
          error: (err, response) => {
            alert('connection error');
            if(err.responseJSON){
              window.location.href = window.location.href.split('#')[0] + '#/';
            }
          }
      });
    }

    getDeclineYearly(){
      $.ajax({
          url: api_route.get_declineyearly,
          type: 'GET',
          success: (response) => {
      this.setState({
              declineyearly : JSON.parse(CryptoJSAesDecrypt(response.data))
            })
            // console.log("dec decline yearly", JSON.parse(CryptoJSAesDecrypt(response.data)))
          //   console.log("state", this.state.data)
          //   console.log("length ",this.state.data.length)
          },
          error: (err, response) => {
            alert('connection error');
            if(err.responseJSON){
              window.location.href = window.location.href.split('#')[0] + '#/';
            }
          }
      });
    }

    getTimeYearly(){
      $.ajax({
          url: api_route.get_timeyearly,
          type: 'GET',
          success: (response) => {
      this.setState({
              timeyearly : JSON.parse(CryptoJSAesDecrypt(response.data))
            })
            // console.log("dec timeyearly", JSON.parse(CryptoJSAesDecrypt(response.data)))
          //   console.log("state", this.state.data)
          //   console.log("length ",this.state.data.length)
          },
          error: (err, response) => {
            alert('connection error');
            if(err.responseJSON){
              window.location.href = window.location.href.split('#')[0] + '#/';
            }
          }
      });
    }

    getJob(selectedYear){
      // console.log(selectedYear)
      let formDataParam = new FormData();
      formDataParam.append('year', selectedYear)
      $.ajax({
          url: api_route.get_job,
          data: formDataParam,
          processData: false,
          contentType:false,
          type: 'POST',
          success: (response) => {
              // console.log(response)
              this.setState({
                      job : JSON.parse(CryptoJSAesDecrypt(response.data))
                    })
              // console.log(response.data)
            // console.log("dec", JSON.parse(CryptoJSAesDecrypt(response.data)))
          //   console.log("state", this.state.data)
          //   console.log("length ",this.state.data.length)
          },
          error: (err, response) => {
            alert('connection error');
            if(err.responseJSON){
              window.location.href = window.location.href.split('#')[0] + '#/';
            }
          }
      });
    }

    getInfo(selectedYear){
      // console.log(selectedYear)
      let formDataParam = new FormData();
      formDataParam.append('year', selectedYear)
      $.ajax({
          url: api_route.get_info,
          data: formDataParam,
          processData: false,
          contentType:false,
          type: 'POST',
          success: (response) => {
              // console.log(response)
              this.setState({
                info : JSON.parse(CryptoJSAesDecrypt(response.data))
              })
              // console.log(response.data)
            // console.log("dec", JSON.parse(CryptoJSAesDecrypt(response.data)))
          //   console.log("state", this.state.data)
          //   console.log("length ",this.state.data.length)
          },
          error: (err, response) => {
            alert('connection error');
            if(err.responseJSON){
              window.location.href = window.location.href.split('#')[0] + '#/';
            }
          }
      });
    }

    getReqInfo(selectedYear){
      // console.log(selectedYear)
      let formDataParam = new FormData();
      formDataParam.append('year', selectedYear)
      $.ajax({
          url: api_route.get_reqinfo,
          data: formDataParam,
          processData: false,
          contentType:false,
          type: 'POST',
          success: (response) => {
              // console.log(response)
              this.setState({
                      reqinfo : JSON.parse(CryptoJSAesDecrypt(response.data))
                    })
              // console.log(response.data)
            // console.log("dec", JSON.parse(CryptoJSAesDecrypt(response.data)))
          //   console.log("state", this.state.data)
          //   console.log("length ",this.state.data.length)
          },
          error: (err, response) => {
            alert('connection error');
            if(err.responseJSON){
              window.location.href = window.location.href.split('#')[0] + '#/';
            }
          }
      });
    }

    getMedia(selectedYear){
      // console.log(selectedYear)
      let formDataParam = new FormData();
      formDataParam.append('year', selectedYear)
      $.ajax({
          url: api_route.get_media,
          data: formDataParam,
          processData: false,
          contentType:false,
          type: 'POST',
          success: (response) => {
              // console.log(response)
              this.setState({
                      media : JSON.parse(CryptoJSAesDecrypt(response.data))
                    })
              // console.log(response.data)
            // console.log("dec", JSON.parse(CryptoJSAesDecrypt(response.data)))
          //   console.log("state", this.state.data)
          //   console.log("length ",this.state.data.length)
          },
          error: (err, response) => {
            alert('connection error');
            if(err.responseJSON){
              window.location.href = window.location.href.split('#')[0] + '#/';
            }
          }
      });
    }



    componentDidMount(){
    // console.log(this.curryear)
    this.getStatCustCare();
    this.getYear();
    this.getJob((new Date()).getFullYear());
    this.getInfo((new Date()).getFullYear());
    this.getReqInfo((new Date()).getFullYear());
    this.getStat();
    this.getAllYearly();
    this.getApproveYearly();
    this.getDeclineYearly();
    this.getTimeYearly();
    this.getMedia((new Date()).getFullYear());

    }

    render() {
        let data = this.state.data;
        let slip = [];
        let slip_list = [];
        let jobname_list = [];
        let jobval_list = [];
        let infoname_list = [];
        let infoval_list=[];
        let req_sum = [];
        let req_approve = [];
        let req_decline = [];
        let avg_wkt = [];
        let bg_color = [];
        let bd_color = [];
        let inforeq_list = [];
        let month = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
        let year = this.state.year;
        let job = this.state.job;
        let info = this.state.info;
        let reqinfo = this.state.reqinfo;
        let stat = this.state.stat;
        let allyearly = this.state.allyearly;
        let approveyearly = this.state.approveyearly;
        let declineyearly = this.state.declineyearly;
        let timeyearly = this.state.timeyearly;
        let media = this.state.media;
        let stat_custcare = this.state.stat_custcare;
        // let year_list = [];
        let allyearly_list = [];
        let approveyearly_list = [];
        let declineyearly_list = [];
        let timeyearly_list = [];
        let stat_list = [];
        let media_list = [];
        let stat_custcare_list = [];
        let rangeyear = this.rangeyear;
        let curryear = this.curryear;

        // if(year != null){
        //   Object.entries(year).map((value, index)=>{
        //     // console.log(year)
        //     year_list.push(value[1]['tahun'])
        //   })
        //   console.log(year_list)
        // }

        if(job != null){
          Object.entries(job).map((value, index)=>{
            // console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
            // console.log(value[1]['pekerjaan'])
            jobname_list.push(value[1]['pekerjaan'])
            jobval_list.push(value[1]['jml'])
          })
        }

        if(info != null){
          Object.entries(info).map((value, index)=>{
            // console.log(value)
            infoname_list.push(value[1]['jenis_informasi'])
            infoval_list.push(value[1]['jml'])
          })
        }

        if(allyearly != null){
          Object.entries(allyearly).map((value, index)=>{
            for (var i = 0; i < value[1].length; i++) {
              allyearly_list.push(value[1][i]['jml_pemohon'])
            }
          })
          // console.log(allyearly_list)
        }

        if(approveyearly != null){
          Object.entries(approveyearly).map((value, index)=>{
            // console.log(value)
            for (var i = 0; i < value[1].length; i++) {
              approveyearly_list.push(value[1][i]['approved'])
            }
          })
          // console.log(approveyearly_list)
        }

        if(declineyearly != null){
          Object.entries(declineyearly).map((value, index)=>{
            for (var i = 0; i < value[1].length; i++) {
              declineyearly_list.push(value[1][i]['declined'])
            }
          })
          // console.log(declineyearly_list)
        }

        if(timeyearly != null){
          Object.entries(timeyearly).map((value, index)=>{
            for (var i = 0; i < value[1].length; i++) {
              timeyearly_list.push(value[1][i]['avg_time'])
            }
          })
          // console.log(timeyearly_list)
        }

        if(reqinfo != null){
          Object.entries(reqinfo).map((value, index)=>{
            inforeq_list.push(value[1])
          })
        }

        if(stat != null){
          // console.log(num.toFixed(2))
          Object.entries(stat).map((value, index)=>{
            // console.log(value.type)
            // if(val)
            stat_list.push(value[1])
          })
          // console.log(stat_list)
        }

        if(media != null){
          Object.entries(media).map((value, index)=>{
            // console.log(value)
            media_list.push(value[1]['call_center'])
            media_list.push(value[1]['email'])
            media_list.push(value[1]['facebook'])
            media_list.push(value[1]['instagram'])
            media_list.push(value[1]['tcare'])
          })
          // console.log(media_list)
        }

        if(stat_custcare != null){
          // console.log(num.toFixed(2))
          Object.entries(stat_custcare).map((value, index)=>{
            // console.log(value[1])
            stat_custcare_list.push(value[1])
            // stat_custcare_list.push(value['informasi'])
            // stat_custcare_list.push(value['jml_pemohon'])
            // stat_custcare_list.push(value['pst_aktif'])
            // stat_custcare_list.push(value['pst_pensiun'])
            // stat_custcare_list.push(value['non_pst'])
          })
          // console.log(stat_custcare_list)
        }

        return (

            // <section id="statistik" className="statis">
            <div className="App about">
            <Header role="2"/>
            <div className='container pb-4'>
                <div className="section-title pb-0 pt-4">
                  <h2>Statistik Layanan Informasi Publik</h2>
                </div>
                <div class="pricing pricing-palden">
                    <div class="pricing-item">
                        <div class="pricing-deco">
                            <div class="pricing-price">{stat_list[0]} %</div>
                        </div>
                        <h3 class="pricing-title">Permohonan Diberikan</h3>
                    </div>
                    <div class="pricing-item pricing__item--featured">
                        <div class="pricing-deco">
                            <div class="pricing-price">{stat_list[1]}</div>
                        </div>
                        <h3 class="pricing-title">Jumlah Pemohon</h3>
                    </div>
                    <div class="pricing-item">
                        <div class="pricing-deco">
                            <div class="pricing-price">{stat_list[2]} %</div>
                        </div>
                        <h3 class="pricing-title">Permohonan Ditolak</h3>
                    </div>
                </div>

                <div className="section-title">
                <h2>Statistik Layanan Customer Care</h2>
                </div>

                <div class="pricing pricing-palden">
                    <div class="pricing-item">
                        <div class="pricing-deco">
                            <div class="pricing-price">{stat_custcare_list[0]}
                                <span class="pricing-period"></span>
                            </div>
                        </div>
                        <h3 class="pricing-title">Permintaan Informasi</h3>
                    </div>
                    <div class="pricing-item pricing__item--featured">
                        <div class="pricing-deco">
                          <div class="pricing-price">
                            <ul style={{listStyleType: "none"}}>
                                <li style={{padding: "0 0 0.5em 0", fontSize: "0.5em"}}>
                                    {/* <span class="pricing-currency">Aktif</span> */}
                                    {stat_custcare_list[2]}
                                    <span class="pricing-period" >Aktif</span>
                                </li>
                                <li style={{padding: "0 0 0.5em 0", fontSize: "0.5em"}}>
                                    {/* <span class="pricing-currency">Pensiunan</span> */}
                                    {stat_custcare_list[3]}
                                    <span class="pricing-period" >Pensiunan</span>
                                </li>
                                <li style={{ fontSize: "0.5em"}}>
                                    {/* <span class="pricing-currency">Non Peserta</span> */}
                                    {stat_custcare_list[4]}
                                    <span class="pricing-period" >Non Peserta</span>
                                </li>
                            </ul>
                          </div>
                        </div>
                        <h3 class="pricing-title">Status Pemohon</h3>
                    </div>
                    <div class="pricing-item">
                        <div class="pricing-deco">
                            <div class="pricing-price">{stat_custcare_list[1]}</div>
                        </div>
                        <h3 class="pricing-title">Jumlah Pemohon</h3>
                    </div>
                </div>

                    {/* <div className="section-title">
                    <h2>Statistik Layanan Informasi Publik</h2>
                    </div> */}


                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "5rem"}}>
                    <div>
                        <select onChange={e => this.getJob(e.target.value)}>
                        <option value={curryear}>Pilih Tahun</option>
                            {
                              rangeyear.map((year, index) => {
                                  return <option key={`year${index}`} value={year}>{year}</option>
                              })
                              }
                        </select>
                        <Pie
                        data = {{
                                labels: jobname_list,
                                datasets: [
                                {
                                    label: '',
                                    data: jobval_list,
                                    backgroundColor:
                                    // bg_color,
                                    [
                                      // 'rgba(13, 75, 123, 0.5)',
                                      // 'rgba(11, 91, 153, 0.5)',
                                      // 'rgba(12, 114, 189, 0.5)',
                                      // 'rgba(25, 144, 222, 0.5)',
                                      // 'rgba(66, 170, 238, 0.5)',
                                      // 'rgba(131, 199, 246, 0.5)',
                                      // 'rgba(189, 224, 250, 0.5)',
                                      // 'rgba(226, 240, 252, 0.5)',
                                      // 'rgba(241, 248, 254, 0.5)',
                                      '#0d4b7b',
                                      '#0c72bd',
                                      '#42aaee',
                                      '#bde0fa',
                                      '#f1f8fe',
                                    ],
                                    borderColor:
                                    // bd_color,
                                    [
                                      '#0d4b7b',
                                      '#0c72bd',
                                      '#42aaee',
                                      '#bde0fa',
                                      '#f1f8fe',
                                    ],
                                    borderWidth: 1,
                                    datalabels: {
                                      labels: {
                                        index: {
                                          align: 'end',
                                          anchor: 'center',
                                          color: 'black',
                                          // function(ctx) {
                                          //   return ctx.dataset.backgroundColor;
                                          // },
                                          font: {size: 15 },
                                          color: 'black',
                                          formatter: function(value, ctx) {
                                            return value
                                          },
                                          // offset: 8,
                                          // opacity: function(ctx) {
                                          //   return ctx.active ? 1 : 0.5;
                                          // }
                                        },
                                        // name: {
                                        //   align: 'top',
                                        //   font: {size: 8},
                                        //   formatter: function(value, ctx) {
                                        //     return ctx.active
                                        //       ? 'name'
                                        //       : ctx.chart.data.labels[ctx.dataIndex];
                                        //   }
                                        // },
                                        // value: {
                                        //   align: 'bottom',
                                        //   backgroundColor: 'black',
                                        //   // function(ctx) {
                                        //   //   var value = ctx.dataset.data[ctx.dataIndex];
                                        //   //   return value > 50 ? 'black' : null;
                                        //   // },
                                        //   borderColor: 'black',
                                        //   borderWidth: 2,
                                        //   borderRadius: 4,
                                        //   color: function(ctx) {
                                        //     var value = ctx.dataset.data[ctx.dataIndex];
                                        //     return value > 50
                                        //       ? ctx.dataset.backgroundColor
                                        //       : 'white';
                                        //   },
                                        //   formatter: function(value, ctx) {
                                        //     return ctx.active
                                        //       ? 'value'
                                        //       : Math.round(value * 1000) / 1000;
                                        //   },
                                        //   padding: 4
                                        // }
                                      }
                                    }
                                },
                                ],
                            }}
                            options = {
                                {indexAxis: 'y',
                                elements: {
                                  bar: {
                                    borderWidth: 2,
                                  },
                                },
                                responsive: true,
                                plugins: {
                                  legend: {
                                    position: 'bottom',
                                    display: true
                                  },
                                  datalabels: {
                                  //   datalabels: {
                                  //     color: 'white',
                                  //     display: function(ctx) {
                                  //       return ctx.dataset.data[ctx.dataIndex] > 10;
                                  //     },
                                  //     font: {
                                  //       weight: 'bold',
                                  //     },
                                  //     offset: 0,
                                  //     padding: 0
                                  //   }
                                  // },

                                  // // Core options
                                  // aspectRatio: 3 / 2,
                                  // cutoutPercentage: 8,
                                  // layout: {
                                  //   padding: 16
                                  // },
                                  // elements: {
                                  //   line: {
                                  //     fill: false,
                                  //     tension: 0.4
                                  //   },
                                  //   point: {
                                  //     hoverRadius: 7,
                                  //     radius: 5
                                  //   }
                                    // display: true,
                                    // color: "black",
                                    // align: function(context) {
                                    //   return 'end';
                                    // },
                                    // font: {
                                    //   weight: 'bold',
                                    // },
                                    // formatter: function(job, context) {
                                    //   return context.dataIndex + ': ' + job + '\'';
                                    // },
                                    // offset: 8,
                                    // padding: 6,
                                  },
                                  title: {
                                    display: true,
                                    text: 'Data Pekerjaan',
                                  },
                                },}
                              }
                        />
                      </div>
                      <div>
                        <select onChange={e => this.getInfo(e.target.value)}>
                            <option value={curryear}>Pilih Tahun</option>
                            {
                            rangeyear.map((year, index) => {
                                return <option key={`year${index}`} value={year}>{year}</option>
                            })
                            }
                        </select>
                        <Pie
                        data = {{
                                labels: infoname_list,
                                datasets: [
                                {
                                    label: '# of Votes',
                                    data: infoval_list,
                                    backgroundColor:
                                    // bg_color,
                                    [
                                      '#d2ae23',
                                      '#dfc330',
                                      '#e8d65c',
                                      '#f0e998',
                                      '#f7f5ca',
                                    ],
                                    borderColor:
                                    // bd_color,
                                    [
                                      '#d2ae23',
                                      '#dfc330',
                                      '#e8d65c',
                                      '#f0e998',
                                      '#f7f5ca',
                                    ],
                                    borderWidth: 1,
                                    datalabels: {
                                      labels: {
                                        index: {
                                          align: 'end',
                                          anchor: 'center',
                                          color: 'black',
                                          // function(ctx) {
                                          //   return ctx.dataset.backgroundColor;
                                          // },
                                          font: {size: 15},
                                          color: 'black',
                                          formatter: function(value, ctx) {
                                            return value
                                          },
                                          // offset: 8,
                                          // opacity: function(ctx) {
                                          //   return ctx.active ? 1 : 0.5;
                                          // }
                                        },
                                        // name: {
                                        //   align: 'top',
                                        //   font: {size: 8},
                                        //   formatter: function(value, ctx) {
                                        //     return ctx.active
                                        //       ? 'name'
                                        //       : ctx.chart.data.labels[ctx.dataIndex];
                                        //   }
                                        // },
                                        // value: {
                                        //   align: 'bottom',
                                        //   backgroundColor: 'black',
                                        //   // function(ctx) {
                                        //   //   var value = ctx.dataset.data[ctx.dataIndex];
                                        //   //   return value > 50 ? 'black' : null;
                                        //   // },
                                        //   borderColor: 'black',
                                        //   borderWidth: 2,
                                        //   borderRadius: 4,
                                        //   color: function(ctx) {
                                        //     var value = ctx.dataset.data[ctx.dataIndex];
                                        //     return value > 50
                                        //       ? ctx.dataset.backgroundColor
                                        //       : 'white';
                                        //   },
                                        //   formatter: function(value, ctx) {
                                        //     return ctx.active
                                        //       ? 'value'
                                        //       : Math.round(value * 1000) / 1000;
                                        //   },
                                        //   padding: 4
                                        // }
                                      }
                                    }
                                },
                                ],
                            }}
                            options = {
                                {indexAxis: 'y',
                                elements: {
                                  bar: {
                                    borderWidth: 2,
                                  },
                                },
                                responsive: true,
                                plugins: {
                                  legend: {
                                    position: 'bottom',
                                    display: true
                                  },
                                  // datalabels: {
                                  //   display: true,
                                  //   color: "black",
                                  //   font: {
                                  //     weight: 'bold',
                                  //   },
                                  //   formatter: function(value, context) {
                                  //     return context.dataIndex + ': ' + Math.round(value) + '\'';
                                  //   },
                                  //   offset: 8,
                                  //   padding: 6,
                                  // },
                                  title: {
                                    display: true,
                                    text: 'Tujuan Penggunaan Informasi',
                                  },
                                }
                                }


                              }
                        />
                      </div>
                    </div>
                    <br></br>
                    <br></br>

                    <div className="section-title">
                    <h2>Data Permohonan Informasi Publik PT TASPEN (Persero)</h2>
                    </div>

                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 2fr)", gridGap: 20, width: "100%", height: "30%" }}>
                        <Bar
                              data={{
                                labels: ['2020', '2021', '2022', '2023'],
                                datasets: [
                                    {
                                        label: 'Jumlah Pemohon',
                                        data: allyearly_list,
                                        borderColor: '#0d4b7b',
                                        backgroundColor: '#0d4b7b',
                                    },
                                    {
                                        label: 'Permohonan Dikabulkan',
                                        data: approveyearly_list,
                                        borderColor: '#d2ae23',
                                        backgroundColor: '#d2ae23',
                                    },
                                    {
                                        label: 'Permohonan Ditolak',
                                        data: declineyearly_list,
                                        borderColor: '#c0dff7',
                                        backgroundColor: '#c0dff7',
                                    },
                                    {
                                        label: 'Rata-rata waktu Layanan (Hari)',
                                        data: timeyearly_list,
                                        borderColor: '#e8d65c',
                                        backgroundColor: '#e8d65c',
                                    },
                                ]}
                              }
                              options = {
                                {indexAxis: 'y',
                                elements: {
                                  bar: {
                                    borderWidth: 2,
                                  },
                                },
                                responsive: true,
                                plugins: {
                                  legend: {
                                    position: 'right',
                                  },
                                  // title: {
                                  //   display: true,
                                  //   text: 'Data Permohonan Informasi Publik PT TASPEN (Persero)',
                                  // },
                                },
                                scales: {
                                  x: {
                                    grid: {
                                      display:false
                                    }
                                  },
                                  y: {
                                    grid: {
                                      display:false
                                    }
                                  }
                                }}
                              }
                            // options={{
                            // plugins: {
                            //     title: {
                            //     display: true,
                            //     text: "Users Gained between 2016-2020"
                            //     },
                            //     legend: {
                            //     display: false
                            //     }
                            // }
                            // }}
                        />
                    </div>
                    <br></br>

                    <div className="section-title">
                    <h2>Statistik Permintaan Informasi Layanan Customer Care</h2>
                    </div>


                    <div style={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "5rem"}}>
                    <div>

                        <select onChange={e => this.getReqInfo(e.target.value)}>
                            <option value={curryear}>Pilih Tahun</option>
                            {
                            rangeyear.map((year, index) => {
                                return <option key={`year${index}`} value={year}>{year}</option>
                            })
                            }
                        </select>
                        <Bar
                              data={{
                                labels: ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'],
                                datasets: [
                                    {
                                        label: '',
                                        data: inforeq_list[0],
                                        backgroundColor:
                                    // bg_color,
                                    [
                                      '#0d4b7b',
                                    ],
                                    borderColor:
                                    // bd_color,
                                    [
                                      '#0d4b7b',
                                    ],
                                    },
                                ]}
                              }
                              options = {
                                {
                                // indexAxis: 'y',
                                elements: {
                                  bar: {
                                    borderWidth: 2,
                                  },
                                },
                                responsive: true,
                                plugins: {
                                  legend: {
                                    display: false,
                                  },
                                  // title: {
                                  //   display: true,
                                  //   text: 'Permintaan Informasi',
                                  // },
                                },scales: {
                                  x: {
                                    grid: {
                                      display:false
                                    }
                                  },
                                  y: {
                                    grid: {
                                      display:false
                                    }
                                  }
                                }}
                              }
                        />
                    </div>
                    <div >

                        <select onChange={e => this.getMedia(e.target.value)}>
                            <option value={curryear}>Pilih Tahun</option>
                            {
                            rangeyear.map((year, index) => {
                                return <option key={`year${index}`} value={year}>{year}</option>
                            })
                            }
                        </select>
                        <Bar
                              data={{
                                labels: ['Call Center', 'Email', 'Facebook', 'Instagram', 'Tcare'],
                                datasets: [
                                    {
                                        label: '',
                                        data: media_list,
                                        backgroundColor:
                                    // bg_color,
                                    [
                                      '#d2ae23',
                                      '#dfc330',
                                      '#e8d65c',
                                      '#f0e998',
                                      '#f7f5ca',
                                    ],
                                    borderColor:
                                    // bd_color,
                                    [
                                      '#d2ae23',
                                      '#dfc330',
                                      '#e8d65c',
                                      '#f0e998',
                                      '#f7f5ca',
                                    ],
                                    },
                                ]}
                              }
                              options = {
                                {
                                // indexAxis: 'y',
                                elements: {
                                  bar: {
                                    borderWidth: 2,
                                  },
                                },
                                responsive: true,
                                plugins: {
                                  legend: {
                                    display: false,
                                  },
                                  // title: {
                                  //   display: true,
                                    text: 'Media',
                                  // },
                                },scales: {
                                  x: {
                                    grid: {
                                      display:false
                                    }
                                  },
                                  y: {
                                    grid: {
                                      display:false
                                    }
                                  }
                                }}
                              }
                        />
                    </div>


                </div>
                </div>
                </div>
        );
    }
}
export default Statistik;
