import React from 'react';
import {BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Home from './components/Home';
import Profile from './components/Profile';
import Regulasi from './components/Regulasi';
import Biodata from './components/user/Biodata';
import _History from './components/user/History';
import Permohonan from './components/user/Permohonan';
import Pengajuan from './components/user/Pengajuan';
import ProsedurPengajuan from './components/layanan_informasi/ProsedurPengajuan';
import ProsedurKeberatan from './components/layanan_informasi/ProsedurKeberatan';
import Berkala from './components/informasi_publik/Berkala';
import Laporan from './components/informasi_publik/Laporan';
import SertaMerta from './components/informasi_publik/SertaMerta';
import SetiapSaat from './components/informasi_publik/SetiapSaat';
import Login from './components/Login';
import Register from './components/Register';
import Statistik from './components/Statistik';
import Jakarta from './components/wilayah/Jakarta';
import Bandung from './components/wilayah/Bandung';
import Surabaya from './components/wilayah/Surabaya';
import Semarang from './components/wilayah/Semarang';
import Medan from './components/wilayah/Medan';
import Makassar from './components/wilayah/Makassar';
import Dikecualikan from './components/informasi_publik/Dikecualikan';
import DaftarInformasiPublik from './components/informasi_publik/DaftarInformasiPublik';

function App() {
  return (

    <Router>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/home" exact component={Home} />
        <Route path="/resetpass" exact component={Home} />
        <Route path="/login" exact component={Login} />
        <Route path="/register" exact component={Register} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/regulasi" exact component={Regulasi} />
        <Route path="/biodata" exact component={Biodata} />
        <Route path="/history" exact component={_History} />
        <Route path="/prosedur_permohonan_informasi" exact component={ProsedurPengajuan} />
        <Route path="/prosedur_pengajuan_keberatan" exact component={ProsedurKeberatan} />
        <Route path="/permohonan_informasi" exact component={Permohonan} />
        <Route path="/pengajuan_keberatan" exact component={Pengajuan} />
        <Route path="/prosedur_permohonan" exact component={ProsedurPengajuan} />
        <Route path="/prosedur_keberatan" exact component={ProsedurKeberatan} />
        <Route path="/informasi_berkala" exact component={Berkala} />
        <Route path="/informasi_laporan" exact component={Laporan} />
        <Route path="/informasi_serta_merta" exact component={SertaMerta} />
        <Route path="/informasi_setiap_saat" exact component={SetiapSaat} />
        <Route path="/informasi_dikecualikan" exact component={Dikecualikan} />
        <Route path="/daftar_informasi_publik" exact component={DaftarInformasiPublik} />
        <Route path="/statistik" exact component={Statistik} />
        <Route path="/ppid_jakarta" exact component={Jakarta} />
        <Route path="/ppid_bandung" exact component={Bandung}/>
        <Route path="/ppid_semarang" exact component={Semarang}/>
        <Route path="/ppid_surabaya" exact component={Surabaya} />
        <Route path="/ppid_medan" exact component={Medan}/>
        <Route path="/ppid_makassar" exact component={Makassar}/>
      </Switch>
    </Router>
  );
} 

export default App;
