import React from 'react';
import logo from '../../logo.svg';
import '../../App.css';
import Header from '../../common_components/Header';
import api_route from '../../common_components/api_route';
import Header2 from '../../common_components/Header2';
import { checkLogin } from '../../common_components/helper';

const $ = require('jquery');

$.Datatable = require('datatables.net')
class Pengajuan extends React.Component{
    
    constructor(props){
		super(props);

		this.state = {
            nama: '',
            data: null,
            pengajuan : null
		}

        this.pengajuan = this.pengajuan.bind(this);
    }

    componentDidMount(){
        if(localStorage.getItem('key') != null && localStorage.getItem('key') != ''){
            $.ajax({
                url: api_route.ceklogin+'/'+localStorage.getItem('key')+'/'+localStorage.getItem('username'),
                type: 'GET',
                success: (response) => {
                console.log('cek login ',response)
                if(response.login_info.status == 'OK'){
                   
                    $.ajax({
                        url: api_route.biodata+'/'+localStorage.getItem('key')+'/'+localStorage.getItem('id_user'),
                        type: 'GET',
                        success: (response) => {
                        this.setState({
                            data : response.data[0]
                        })
                        },
                        error: (err, response) => {
                        alert('connection error');
                        if(err.responseJSON){
                            window.location.href = window.location.href.split('#')[0] + '#/';
                        }
                        }
                    });
                    $.ajax({
                        url: api_route.get_pengajuan+'/'+localStorage.getItem('username'),
                        type: 'GET',
                        success: (response) => {
                        this.setState({
                            pengajuan : response.data
                        })
                        },
                        error: (err, response) => {
                        alert('connection error');
                        if(err.responseJSON){
                            window.location.href = window.location.href.split('#')[0] + '#/';
                        }
                        }
                    });
                }else{
                    localStorage.clear();
                    sessionStorage.clear();
                    // location.reload();
                    window.location.href = "/";
                }
        
                },
                error: (err, response) => {
                //   alert('connection error');
                if(err.responseJSON){
                    alert(err.responseJSON.login_info.msg)
                    // window.location.href = window.location.href.split('#')[0] + '#/';
                }
                }
            });
        }else{
            localStorage.clear();
            sessionStorage.clear();
            // location.reload();
            window.location.href = "/";

        }
    }

    pengajuan = (e) => {
        e.preventDefault();
        
        var pengajuan = document.getElementById('pengajuan').value;
        var keberatan = document.getElementById('keberatan').value;
        
        let formDataParam = new FormData();
        formDataParam.append('id_pengajuan', pengajuan)
        formDataParam.append('rincian_keberatan', keberatan)
        $.ajax({
            url: api_route.pengajuan_keberatan+'/'+localStorage.getItem('username'),
            data: formDataParam,
            processData: false,
            contentType: false,
            type: 'POST',
            success: (response) => {
                alert(response.keberatan_info.msg);
                window.location.href = '/history';

            },
            error: (err, response) => {
                alert(response.keberatan_info.msg)
              if(err.responseJSON){
              	window.location.href = window.location.href.split('#')[0] + '#/';
              }
            }
        });
    }
    render() {
        let data = this.state.data;
        let pengajuan = this.state.pengajuan;
        let pengajuan_list = [];
        console.log(pengajuan)
		if(pengajuan && pengajuan.length > 0){
            console.log('pengajuan')

			pengajuan.map((value, index) => {
                pengajuan_list.push(
                    <option value={value.id_pengajuan} key={index}>{value.judul}</option>
                );
            });
        }
        console.log(pengajuan_list)
        return (
            <div className="App">
                <Header role="2" />
                <div className="about">
                    <div className="container pb-4" >
                        <div className="section-title pb-0 pt-4">
                            <h2>Pengajuan Keberatan</h2>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12">
                                <div className="row" id="regulation">
                                    <div className="col-lg-12 col-md-12 section-header">
                                        <div id="accordion" className="accordion">
                                            <div className="col-12">
                                                <div className="" style={{textAlign:'center'}}>
                                                    <div className="row form">
                                                        <div className="col-md-3" style={{textAlign:'left'}}>Nama</div>
                                                        <div className="col-md-1 show_pdf">:</div>
                                                        <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.nama}</div>
                                                    </div>
                                                    <hr />
                                                    <div className="row form">
                                                        <div className="col-md-3" style={{textAlign:'left'}}>Jenis Identitas</div>
                                                        <div className="col-md-1 show_pdf">:</div>
                                                        <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.id_identitas == 1 ? "KTP" : (data && data.id_identitas == 2 ? "SIM": "PASPOR" )}</div>
                                                    </div>
                                                    <hr />
                                                    <div className="row form">
                                                        <div className="col-md-3" style={{textAlign:'left'}}>No Identitas</div>
                                                        <div className="col-md-1 show_pdf">:</div>
                                                        <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.no_identitas}</div>
                                                    </div>
                                                    <hr />
                                                    <div className="row form">
                                                        <div className="col-md-3" style={{textAlign:'left'}}>Pekerjaan</div>
                                                        <div className="col-md-1 show_pdf">:</div>
                                                        <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.pekerjaan}</div>
                                                    </div>
                                                    <hr />
                                                    <div className="row form">
                                                        <div className="col-md-3" style={{textAlign:'left'}}>Email</div>
                                                        <div className="col-md-1 show_pdf">:</div>
                                                        <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.email}</div>
                                                    </div>
                                                    <hr />
                                                    <div className="row form">
                                                        <div className="col-md-3" style={{textAlign:'left'}}>No Telepon</div>
                                                        <div className="col-md-1 show_pdf">:</div>
                                                        <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.no_telp}</div>
                                                    </div>
                                                    <hr />
                                                    <div className="row form">
                                                        <div className="col-md-3" style={{textAlign:'left'}}>Status User</div>
                                                        <div className="col-md-1 show_pdf">:</div>
                                                        <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>{data && data.status == 1 ? "AKTIF" : "TIDAK AKTIF"}</div>
                                                    </div>
                                                    <hr />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="about">
                    <div className="container pb-4" >
                        <div className="section-title pb-0 pt-4">
                            <h2>Informasi</h2>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12">
                                <div className="row" id="regulation">
                                    <div className="col-lg-12 col-md-12 section-header">
                                        <div id="accordion" className="accordion">
                                            
                                            <form className="form" onSubmit={this.pengajuan.bind(this)}  style={{textAlign:'center'}}>
                                                <div className="row form">
                                                    <div className="col-md-3" style={{textAlign:'left'}}>Pengajuan Informasi*</div>
                                                    <div className="col-md-1 show_pdf">:</div>
                                                    <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>
                                                        <select className="form-control" id="pengajuan" name="pengajuan" required>
                                                            <option value="">-- Pilih Informasi --</option>
                                                            {pengajuan_list}
                                                        </select>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row form">
                                                    <div className="col-md-3" style={{textAlign:'left'}}>Pengajuan Keberatan*</div>
                                                    <div className="col-md-1 show_pdf">:</div>
                                                    <div className="col-md-8 show_pdf" style={{textAlign:'left'}}>
                                                        <textarea className="form-control" placeholder="Pengajuan Keberatan" name="keberatan" id="keberatan" required />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="form" style={{textAlign:'center'}}>
                                                    <div className="form-actions">
                                                        <div className="text-right">
                                                            <button type="submit" className="btn btn-info">Kirim</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
        );
    }
}
export default Pengajuan;
