import React from 'react';
import '../../App.css';
import Header from '../../common_components/Header';
import api_route from '../../common_components/api_route';
import Header2 from '../../common_components/Header2';

const $ = require('jquery');

$.Datatable = require('datatables.net')
class Surabaya extends React.Component{
    
    constructor(props){
		super(props);

		this.state = {
            nama: '',
            data: null
		}

    }

    componentDidMount(){
        const activeURL = window.location.href;
        console.log(activeURL);
    }

    render() {
        let data = this.state.data;
        let laporan_list = [];
		if(data != null && data.length > 0){
			data.map((value, index) => {
                laporan_list.push(
                    <tr key={index}>
                        <td>{}</td>
                        <td>{value.user_created}</td>
                        <td>{value.judul}</td>
                        <td>{value.updstamp}</td>
                        <td>{value.updstamp_balasan}</td>
                        <td>{value.status_tinjut == 1 ? "BELUM TINJUT" : (value.status_tinjut == 2 ? "SUDAH TINJUT": "DITOLAK" )}</td>
                    </tr>

                );
            });
		}
        return (
            <div className="Surabaya about">
                <Header role="2" />
                <div className="container pb-4" >
                    <div className="section-title pb-0 pt-4">
                        <h2>PPID Cabang Surabaya</h2>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-12">
                            <div className="row" id="regulation">
                                <div className="col-lg-12 col-md-12 section-header">
                                    <div id="accordion" className="accordion">
                                        <img src={process.env.PUBLIC_URL + '/assets/img/wilayah/SURABAYA.jpg'} width="1110" alt="Cabang Surabaya" class="img-fluid"></img>
                                        <br></br>
                                        <br></br>
                                        <br></br>
                                        <div id="accordion" className="accordion">
                                            <div className="card-mb-0">
                                                <div className="card-header collapsed row" data-toggle="collapse" style={{textAlign:'left'}}>
                                                    <div className="col-md-11" style={{textAlign:'left'}}>      
                                                        <a className="card-title">Informasi Perusahaan</a>
                                                    </div>
                                                </div>
                                                <div className="card-body collapse multi-collapse show">
                                                    <div className="card-body">
                                                        <div>
                                                            <div className="row form">
                                                                <div className="col-md-7">
                                                                    <a href="https://www.taspen.co.id/tentang-kami" target="_blank" style={{textAlign:'left'}}>Profil Perusahaan</a>
                                                                    <hr/>
                                                                    <a href="https://admineppid.taspen.co.id/admin-eppid/public/assets/file/STRUKTUR%20TERBARU.pdf" target="_blank" style={{textAlign:'left'}}>Struktur Organisasi</a>
                                                                    <hr/>
                                                                    <a href="https://www.taspen.co.id/tentang-kami#visi_misi" target="_blank" style={{textAlign:'left'}}>Visi dan Misi</a>
                                                                    <hr/>
                                                                    <a href="https://www.taspen.co.id/tentang-kami/profil" target="_blank" style={{textAlign:'left'}}>Tugas dan Fungsi Perusahaan</a>
                                                                    <hr/>
                                                                    <a href="https://www.taspen.co.id/kontak_kami/kcu/4" target="_blank" style={{textAlign:'left'}}>Alamat dan Cabang Sewilayah Surabaya</a>
                                                                    <hr/>
                                                                </div>                                                                
                                                                <div className="col-md-5">
                                                                    <img src={process.env.PUBLIC_URL + '/assets/img/wilayah/SATRIA BETA 2.png'} width="300" height="300" class="img-fluid float-right"></img>
                                                                </div>
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-mb-0">
                                                <div className="card-header collapsed row" data-toggle="collapse" style={{textAlign:'left'}}>
                                                    <div className="col-md-11" style={{textAlign:'left'}}>      
                                                        <a className="card-title">Informasi Publik</a>
                                                    </div>
                                                </div>
                                                <div className="card-body collapse multi-collapse show">
                                                    <div className="card-body">
                                                        <div>
                                                            <div className="row form">
                                                                <div className="col-md-7">
                                                                    <a href="https://eppid.taspen.co.id/informasi_berkala" style={{textAlign:'left'}}>Informasi Wajib Berkala</a>
                                                                    <hr/>
                                                                    <a href="https://eppid.taspen.co.id/informasi_setiap_saat" style={{textAlign:'left'}}>Informasi Tersedia Setiap Saat</a>
                                                                    <hr/>
                                                                    <a href="https://eppid.taspen.co.id/informasi_serta_merta" style={{textAlign:'left'}}>Informasi Serta Merta</a>
                                                                    <hr/>
                                                                    <a href="https://admineppid.taspen.co.id/admin-eppid/public/assets/file/DAFTAR%20IP%202023.pdf" style={{textAlign:'left'}}>Daftar Informasi Publik</a>
                                                                    <hr/>
                                                                    <a href="/prosedur_permohonan_informasi" style={{textAlign:'left'}}>Permohonan Informasi</a>
                                                                    <hr/>
                                                                </div>                                                                
                                                                <div className="col-md-5">
                                                                    <img src={process.env.PUBLIC_URL + '/assets/img/wilayah/SATRIA BETA 1.png'} width="300" height="200" class="img-fluid float-right"></img>
                                                                </div>
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-mb-0">
                                                <div className="card-header collapsed row" data-toggle="collapse" style={{textAlign:'left'}}>
                                                    <div className="col-md-11" style={{textAlign:'left'}}>      
                                                        <a className="card-title">Cabang Utama Surabaya</a>
                                                    </div>
                                                </div>
                                                <div className="card-body collapse multi-collapse show">
                                                    <div className="card-body">
                                                        <div>
                                                            <div className="row form">
                                                                <div className="col-md-7" >
                                                                    <p style={{textAlign:'left'}}>
                                                                    Jl. Diponegoro No. 193, Surabaya 60241 Surabaya
                                                                    <br/>
                                                                    <b>Telp.</b> (031) 567 6356, 567 8702
                                                                    </p>
                                                                </div> 
                                                            </div>
                                                            <hr />
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Surabaya;
