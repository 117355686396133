import React from 'react';
import logo from '../logo.svg';
import '../App.css';
import Header from '../common_components/Header';
import api_route from '../common_components/api_route';

const $ = require('jquery');

$.Datatable = require('datatables.net')
class Profile extends React.Component{
    
    constructor(props){
		super(props);

		this.state = {
            nama: '',
            data: null
		}

    }

    componentDidMount(){
        
		$.ajax({
            url: api_route.profil,
            type: 'GET',
            success: (response) => {
			  this.setState({
                data : response.data
              })
            },
            error: (err, response) => {
              if(err.responseJSON){
              	window.location.href = window.location.href.split('#')[0] + '#/';
              }
            }
        });
    }

    render() {
        let data = this.state.data;
        return (
            <section id="profile" className="about">
              <div className="container">
        
                <div className="section-title pb-0 pt-4">
                        <h2>Profil</h2>
                    </div>
                <div className="row no-gutters text-center">
                  <embed src={data && api_route.content_path+data[0].file+"#toolbar=0&navpanes=0&scrollbar=0"} style={{width:'75%', height:'500px', margin:'0 auto'}}/>
                  {/* <div className="col-lg-6 video-box" style={{margin:'auto'}}>
                    <img src="assets/img/about.jpg" className="img-fluid" alt="" />
                    <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a>
                  </div>
        
                  <div className="col-lg-6 d-flex flex-column justify-content-center about-content">
        
                    <div className="section-title">
                      <h2>PPID PT Taspen (Persero)</h2>
                      <p style={{textAlign:'justify'}}>
                      PPID atau Pejabat Pengelola Informasi Dokumentasi merupakan pejabat yang bertanggung jawab di bidang
                      penyimpanan, pendokumentasian, penyediaan dan/atau pelayanan informasi di PT TASPEN (Persero) berdasarkan KD-05/DIR/2015 yang telah disempurnakan 
                      melalui KD-9/DIR/2017 tentang Perubahan Atas Keputusan Direksi PTTaspen (Persero) Nomor KD-05/DIR/2015 Tentang Penunjukan Pejabat Pengelola Informasi Dan Dokumentasi PT Taspen(Persero).

                      </p>
                    </div>
        
                    <div className="icon-box" data-aos="fade-up" data-aos-delay="100" style={{marginTop:'0'}}>
                      
                      <h4 className="title text-left" style={{marginLeft:'0'}}><a href="">ATASAN PPID</a></h4>
                      <ul className="description text-left" style={{marginLeft:'0'}}>
                          <li>Corporate Secretary</li>
                      </ul>
                    </div>
        
                    <div className="icon-box" data-aos="fade-up" data-aos-delay="100" style={{marginTop:'0'}}>
                      
                      <h4 className="title text-left" style={{marginLeft:'0'}}><a href="">PPID</a></h4>
                      <ul className="description text-left" style={{marginLeft:'0'}}>
                          <li>Manajer Humas</li>
                      </ul>
                    </div>

                    <div className="icon-box" data-aos="fade-up" data-aos-delay="100" style={{marginTop:'0'}}>
                      
                      <h4 className="title text-left" style={{marginLeft:'0'}}><a href="">PELAKSANA PPID</a></h4>
                      <ul className="description text-left" style={{marginLeft:'0'}}>
                          <li>Assisten Manajer Keluhan Pelanggan</li>
                          <li>Kepala Bidang Umum & SDM</li>
                          <li>Kepala Seksi Umum & SDM</li>
                      </ul>
                    </div> 
                  </div> */}
                </div>
        
              </div>
            </section>
    
        );
    }
}
export default Profile;
