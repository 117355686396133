
export const RECAPTCHAv2 = {
	// 'SITE_KEY' : '6Le86rEZAAAAAO1jKzokiqoI16QLm1nTsTzy7gG_',
	// 'SECRET_KEY' : '6Le86rEZAAAAAHZz-SWbTaxhoP5BXnsc2j0lX9QO'
	'SITE_KEY' : '6LddXEMfAAAAAKlRzScHC612i_Zl4ecoiGw75VaV',
	'SECRET_KEY' : '6LddXEMfAAAAAEtX8TW8a8esWocKeHzixePKqwb2'
}

export const RECAPTCHAv3 = {
	'SITE_KEY' : '6Ld77rEZAAAAAD4mp9oAZR6cDs2sqBketj6iXKks',
	'SECRET_KEY' : '6Ld77rEZAAAAAE2tYm8VKQzcGwruJLMQiImZ-V_-'
}
