import React from 'react';
import logo from '../logo.svg';
import '../App.css';
import Header from '../common_components/Header';
import api_route from '../common_components/api_route';

const $ = require('jquery');

$.Datatable = require('datatables.net')
class Login extends React.Component{
    
    constructor(props){
		super(props);

		this.state = {
            nama: '',
            data: null
		}
        this.login = this.login.bind(this);
        this.lostpass = this.lostpass.bind(this);

    }

    login() {
        
		var user =document.getElementById('username').value;
		var pass =document.getElementById('pass').value;
		var otp = this.state.otp;
		// if(user == "" || pass == ""){
		// 	this.setState({status: "mandatory"});
		// 	alert('connection error');
		// }else{
        let formDataParam = new FormData();
        formDataParam.append('username', user);
        formDataParam.append('pass', pass);
        $.ajax({
            url: api_route.login,
            data: formDataParam,
            processData: false,
            contentType: false,
            type: 'POST',
            success: (response) => {
                if(response.user_info.status == 'OK'){
                    console.log('value ----- ', response.user_info.data[0]);
                    localStorage.setItem('id_user', response.user_info.data[0].id_user);
                    localStorage.setItem('nama', response.user_info.data[0].nama);
                    localStorage.setItem('username', response.user_info.data[0].username);
                    localStorage.setItem('key', response.user_info.data[0].key);
                    window.location.href =  '/biodata';
                }else{
                    alert(response.user_info.msg)
                }

            },
            error: (err, response) => {
            //   alert('connection error');
              if(err.responseJSON){
                alert(err.responseJSON.user_info.msg)
              	// window.location.href = window.location.href.split('#')[0] + '#/';
              }
            }
        });
    }
    lostpass() {
        
		var email =document.getElementById('email').value;
		// if(user == "" || pass == ""){
		// 	this.setState({status: "mandatory"});
		// 	alert('connection error');
		// }else{
        let formDataParam = new FormData();
        formDataParam.append('email', email);
        $.ajax({
            url: api_route.lostpass,
            data: formDataParam,
            processData: false,
            contentType: false,
            type: 'POST',
            success: (response) => {
                if(response.user_info.status == 'OK'){
                    alert(response.user_info.msg)
                }else{
                    alert(response.user_info.msg)
                }

            },
            error: (err, response) => {
            //   alert('connection error');
              if(err.responseJSON){
                alert(err.responseJSON.user_info.msg)
              	// window.location.href = window.location.href.split('#')[0] + '#/';
              }
            }
        });
    }
    componentDidMount(){
        
		$.ajax({
            url: api_route.slider,
            type: 'GET',
            success: (response) => {
			  this.setState({
                data : response.data
              })
            },
            error: (err, response) => {
            //   alert('connection error');
              if(err.responseJSON){
              	window.location.href = window.location.href.split('#')[0] + '#/';
              }
            }
        });
    }

    render() {
        let data = this.state.data;
        let slider_list = [];
		if(data != null && data.length > 0){
			data.map((value, index) => {
                slider_list.push(
                    <tr key={index}>
                        <td>{value.keterangan}</td>
                        <td style={{textAlign:'center'}}><button className="btn btn-primary text-white">Download</button></td>
                    </tr>

                );
            });
		}
        return (
            <div className="App">
                <Header role="2" />
                
                <div className="limiter">
                    <div className="container-login100">
                        <div className="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-50">
                            <div className="login100-form validate-form">
                                <span className="login100-form-title p-b-33">
                                    Account Login
                                </span>

                                <div className="wrap-input100 validate-input">
                                    <input className="input100" type="text" name="username"  id="username"  placeholder="Username" />
                                    <span className="focus-input100-1"></span>
                                    <span className="focus-input100-2"></span>
                                </div>

                                <div className="wrap-input100 rs1 validate-input input-group" data-validate="Password is required">
                                    <input className="input100" type="password" name="pass" id="pass" placeholder="Password" />
                                    <span className="focus-input100-1"></span>
                                    <span className="focus-input100-2"></span>
                                </div>

                                <div className="container-login100-form-btn m-t-20">
                                    <button className="login100-form-btn" onClick={this.login.bind(this)}>
                                        Sign in
                                    </button>
                                </div>

                                <div className="text-center p-t-45 p-b-4">
                                    <span className="txt1">
                                        Forgot
                                    </span>
                                    <a href="#" className="txt2 hov1" data-toggle="modal">
                                    // href="#myModal"
                                        Username / Password?
                                    </a>
                                </div>

                                <div className="text-center">
                                    <span className="txt1">
                                        Create an account?
                                    </span>

                                    <a href="/register" className="txt2 hov1">
                                        Sign up
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Lupa Password</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="wrap-input100 validate-input">
                                <input className="input100" type="email" name="email"  id="email"  placeholder="Email" />
                                <span className="focus-input100-1"></span>
                                <span className="focus-input100-2"></span>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={this.lostpass.bind(this)}>Kirim Email</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Login;
