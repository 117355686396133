
const base_api = 'https://admineppid.taspen.co.id/admin-eppid/public/api/';
const base_slider = 'https://admineppid.taspen.co.id/admin-eppid/public/assets/slider/';
const base_file = 'https://admineppid.taspen.co.id/admin-eppid/public/assets/file/';
const base_content = 'https://admineppid.taspen.co.id/admin-eppid/public/assets/content/';


const api_route = {
    // Login and Token Related

    home : base_api + 'home/',
    login : base_api + 'ppid/login',
    logout : base_api + 'ppid/logout',
    ceklogin : base_api + 'ppid/ceklogin',
    lostpass : base_api + 'ppid/lostpass',
    resetpass : base_api + 'ppid/resetpass',
    reg_user : base_api + 'ppid/reg_user',
    biodata : base_api + 'ppid/biodata',
    regulasi : base_api + 'ppid/list_regulasi',
    faq : base_api + 'ppid/list_faq',
    statistik : base_api + 'ppid/statistik',
    history : base_api + 'ppid/history',
    list_layanan : base_api + 'ppid/list_layanan',
    slider : base_api + 'ppid/slider',
    cara_info : base_api + 'ppid/cara_info',
    profil : base_api + 'ppid/profil',
    pengajuan : base_api + 'ppid/pengajuan',
    get_pengajuan : base_api + 'ppid/get_pengajuan',
    keberatan : base_api + 'ppid/keberatan',
    pengajuan_keberatan : base_api + 'ppid/pengajuan_keberatan',
    permohonan_informasi : base_api + 'ppid/permohonan_informasi',
    berkala : base_api + 'ppid/list_info',
    slider_path : base_slider,
    file_path : base_file,
    content_path : base_content,
    statistik : base_api + 'ppid/statistik_summary',
    get_year : base_api + 'ppid/year',
    get_job : base_api + 'ppid/job',
    get_info : base_api + 'ppid/info',
    get_reqinfo : base_api + 'ppid/reqinfo',
    get_stat : base_api + 'ppid/statistik',
    get_allyearly : base_api + 'ppid/allyearly',
    get_approveyearly : base_api + 'ppid/approveyearly',
    get_declineyearly : base_api + 'ppid/declineyearly',
    get_timeyearly : base_api + 'ppid/timeyearly',
    get_media : base_api + 'ppid/media',
    get_stat_custcare : base_api +'ppid/statistik/cust_care',
    survey : base_api + 'ppid/survey'
    


};

export default api_route;
