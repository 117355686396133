import React from 'react';
import '../../App.css';
import Header from '../../common_components/Header';
import api_route from '../../common_components/api_route';
import Header2 from '../../common_components/Header2';

const $ = require('jquery');

$.Datatable = require('datatables.net')
class Dikecualikan extends React.Component{
    
    constructor(props){
		super(props);

		this.state = {
            nama: '',
            berkala: null
		}
        this.downloadPdf = this.downloadPdf.bind(this);

    }

    componentDidMount(){
        
		$.ajax({
            url: api_route.berkala+'/6',
            type: 'GET',
            success: (response) => {
			  this.setState({
                setiapSaat : response.data && response.data.jenis_info,
                child : response.data && response.data.child,
              })
            },
            error: (err, response) => {
              alert('connection error');
              if(err.responseJSON){
              	window.location.href = window.location.href.split('#')[0] + '#/';
              }
            }
        });
    }
    downloadPdf(param) {
        if (param != null || param != undefined) {
            window.open('assets/file/regulasi/'+param+'.pdf');
        }
    }

    render() {
        let setiapSaat = this.state.setiapSaat;
        let child = this.state.child;
        let setiapSaat_list = [];
		if(setiapSaat != null && setiapSaat.length > 0){
			setiapSaat.map((value, index) => {
                setiapSaat_list.push(
                    <div className="card mb-0" key={index}>
                        <div className="card-header collapsed row" data-toggle="collapse" href={"#collapse"+index} style={{textAlign:'left'}}>
                            <div className="col-md-11" style={{textAlign:'left'}}>      
                                <a className="card-title">
                                {value.keterangan}
                                </a>
                            </div>
                            {((value.url == '' || value.url == null) && (value.file == null || value.file == '')) ? '' : <div className="col-md-1 show_pdf" ><a href={(value.url == '' || value.url == null) ?  api_route.file_path+value.file : value.url} target="_blank">Lihat</a></div>}
                        </div>
                        {child[index].length > 0 ? 
                        <div id={"collapse"+index} className="card-body collapse multi-collapse show" >
                            <div className="card-body">
                                {child[index].map((val, key) => 
                                    <div>
                                        <div className="row form" key={key}>
                                            <div className="col-md-11" style={{textAlign:'left'}}>{val.keterangan}</div>
                                            <div className="col-md-1 show_pdf"><a href={(val.url == '' || val.url == null) ?  api_route.file_path+val.file : val.url} target="_blank">Lihat</a></div>
                                        </div>
                                        <hr />
                                    </div>
                                )}
                            </div>
                        </div>
                        : ''}
                    </div>

                );
            });
		}
        return (
            <div className="App about">
                <Header role="2" />
                <div className="container pb-4" >
                    <div className="section-title pb-0 pt-4">
                        <h2>Daftar Informasi Dikecualikan</h2>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-12">
                            <div className="row" id="regulation">
                                <div className="col-lg-12 col-md-12 section-header">
                                    <div id="accordion" className="accordion">
                                        {setiapSaat_list}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default Dikecualikan;
