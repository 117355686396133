import React from 'react';
import '../../App.css';
import Header from '../../common_components/Header';
import api_route from '../../common_components/api_route';
import Register from '../Register';

const $ = require('jquery');

$.Datatable = require('datatables.net')
class ProsedurPengajuan extends React.Component{

    constructor(props){
		super(props);

		this.state = {
            nama: '',
            data: null
		}

    }

    componentDidMount(){

		$.ajax({
            url: api_route.pengajuan,
            type: 'GET',
            success: (response) => {
			  this.setState({
                data : response.data
              })
            },
            error: (err, response) => {
              if(err.responseJSON){
              	window.location.href = window.location.href.split('#')[0] + '#/';
              }
            }
        });
    }

    render() {
        let data = this.state.data;
        return (
            <section id="permohonan-inf" className="about section-bg">
                <Header role="2" />
                <div className="container text-center" style={{backgroundColor:'white'}}>
                    <div className="section-title pb-0 pt-4">
                        <h2>Prosedur Permohonan Informasi</h2>
                    </div>
                    <div className="row no-gutters">
                        <div className="col-lg-12 video-box" style={{margin:'auto'}}>
                            <embed src={data && api_route.content_path+data[0].file+"#toolbar=0&navpanes=0&scrollbar=0"} style={{width:'75%', height:'500px', margin:'0 auto'}}/>
                            {/* <img src={api_route.content_path+"pengajuan.PNG"} className="img-fluid" alt="" />
                            <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a> */}
                        </div>
                    </div>
                </div>
                { this.state && this.state.isVerified != true ? <Register /> : ''}
            </section>
        );
    }
}
export default ProsedurPengajuan;
