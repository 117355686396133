import React from 'react';
import logo from '../logo.svg';
import '../App.css';
import Header from '../common_components/Header';
import api_route from '../common_components/api_route';
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHAv2 } from '../common_components/constant';

const $ = require('jquery');

$.Datatable = require('datatables.net')
class Register extends React.Component{
    
    constructor(props){
		super(props);

		this.state = {
            nama: '',
            data: null,
            passicon1 : 'bx bx-show',
            passicon2 : 'bx bx-show',
            passicon1_status : false,
            passicon2_status : false,
            jenis_id: 0
		}
        // this.register = this.register.bind(this);
        this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.showPass = this.showPass.bind(this);
        this.showPass2 = this.showPass2.bind(this);
        this.changeIdentitas = this.changeIdentitas.bind(this); 

    }

    register = (e) => {
        e.preventDefault();
        var nama = document.getElementById('nama').value;
        var id_identitas = document.getElementById('id_identitas').value;
        var no_identitas = document.getElementById('no_identitas').value;
        var pekerjaan = document.getElementById('pekerjaan').value;
        var email = document.getElementById('mail').value;
        var no_telp = document.getElementById('no_telp').value;
        var username = document.getElementById('uname').value;
        var password = document.getElementById('password').value;
        var retype_password = document.getElementById('retype-password').value;
        // alert(nama)
        // alert(username)
        // alert(email)
        if((nama.trim()).length > 0){
          console.log((nama.trim()).length)
          if((id_identitas.trim()).length > 0){
            if((no_identitas.trim()).length > 0){
              if((pekerjaan.trim()).length > 0){
                if((no_telp.trim()).length > 0 && (no_telp.trim()).length < 16){
                // if(user == "" || pass == ""){
                //   this.setState({status: "mandatory"});
                //   alert('connection error');
                // }else{
                    let formDataParam = new FormData();
                    formDataParam.append('nama', nama)
                    formDataParam.append('id_identitas', id_identitas)
                    formDataParam.append('no_identitas', no_identitas)
                    formDataParam.append('pekerjaan', pekerjaan)
                    formDataParam.append('email', email)
                    formDataParam.append('no_telp', no_telp)
                    formDataParam.append('id_pernyataan', 1)
                    formDataParam.append('username', username)
                    formDataParam.append('pass', password)
                    formDataParam.append('retype_pass', retype_password)
                    formDataParam.append('jenis_user', 1)
                    formDataParam.append('status', 2)
                    $.ajax({
                        url: api_route.reg_user,
                        data: formDataParam,
                        processData: false,
                        contentType: false,
                        type: 'POST',
                        success: (response) => {
                            alert(response.user_info.msg)
            
                            window.location.href = '/';
                        },
                        error: (err, response) => {
                            alert(response.user_info.msg)

                            window.location.href = '/';
                        //   if(err.responseJSON){
                        //   	window.location.href = window.location.href.split('#')[0] + '#/';
                        //   }
                        }
                    });
                // }
                  }else{
                    alert('No Telp tidak boleh kosong dan Max 15 digit');
                  }
              }else{
                alert('Pekerjaan tidak boleh kosong');
              }
            }else{
              alert('No identitas tidak boleh kosong');
            }
          }else{
            alert('Dd identitas tidak boleh kosong');
          }
        }else{
          alert('Nama tidak boleh kosong');
        }
    }
    componentDidMount(){
        $.ajax({
            url: api_route.slider,
            type: 'GET',
            success: (response) => {
			        this.setState({
                data : response.data
              })
            },
            error: (err, response) => {
              alert('connection error');
              if(err.responseJSON){
              	window.location.href = window.location.href.split('#')[0] + '#/';
              }
            }
        });
    }
    onLoadRecaptcha() {
      if (this.captchaDemo) {
          this.captchaDemo.reset();
      }
    }
    verifyCallback(recaptchaToken) {
      // Here you will get the final recaptchaToken!!!  
      console.log(recaptchaToken, "<= your recaptcha token")
      if(recaptchaToken != null || recaptchaToken != ''){
        document.getElementById('reg_submit').disabled = false
      }
    }
    
    showPass() {
      console.log("a")
      if(this.state.passicon1_status == false){
          this.setState({
            passicon1: 'bx bx-hide',
            passicon1_status : true
          })
          document.getElementById('password').type = 'text';
      }else{
        this.setState({
          passicon1: 'bx bx-show',
          passicon1_status : false
        })
          document.getElementById('password').type = 'password';
      }
    }
    
    showPass2() {
      console.log("a")
      if(this.state.passicon2_status == false){
          this.setState({
            passicon2: 'bx bx-hide',
            passicon2_status : true
          })
          document.getElementById('retype-password').type = 'text';
      }else{
        this.setState({
          passicon2: 'bx bx-show',
          passicon2_status : false
        })
          document.getElementById('retype-password').type = 'password';
      }
    }
    
    changeIdentitas(e) {
      console.log(e.target.value)
      this.setState({
        jenis_id: e.target.value
      })
    }

    render() {
        let data = this.state.data;
        let slider_list = [];
		if(data != null && data.length > 0){
			data.map((value, index) => {
                slider_list.push(
                    <tr key={index}>
                        <td>{value.keterangan}</td>
                        <td style={{textAlign:'center'}}><button className="btn btn-primary text-white">Download</button></td>
                    </tr>

                );
            });
		}
        return (
            <section id="register" className="contact">
              <div className="container">
        
                <div className="section-title">
                  <br/>
                  <h2>Registrasi</h2>
                </div>
        
                <div className="row">
                  <div className="col-lg-12" data-aos="fade-up" data-aos-delay="300">
                    <form className="php-email-form" onSubmit={this.register.bind(this)} autoComplete="off" style={{backgroundColor:'#fff'}}>
                      <div className="section-title text-left">
                        <h3>Identitas Diri</h3>
                        <hr />
                      </div>
                      <div className="form-row">
                        <div className="col-lg-6 form-group">
                          <input type="text" name="nama" className="form-control" id="nama" placeholder="Nama Lengkap" maxLength="50" required />
                          <div className="validate"></div>
                        </div>
                        <div className="col-lg-2 form-group">
                          <select className="form-control" name="id_identitas"  id="id_identitas" onChange={this.changeIdentitas.bind(this)} required>
                              <option value="" defaultValue>Jenis Identitas</option>
                              <option value="1">KTP</option>
                              <option value="2">SIM</option>s
                              <option value="3">Paspor</option>
                          </select>
                        </div>
                        <div className="col-lg-4 form-group">
                          <input type="text" name="no_identitas" className="form-control" id="no_identitas" placeholder="No Identitas" maxLength="15" data-rule="minlen:16" data-msg="Please enter at least 16 chars" required />
                          <div className="validate"></div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-lg-6 form-group">
                          <input type="text" name="pekerjaan" className="form-control" id="pekerjaan" placeholder="Pekerjaan" maxLength="50" required />
                          <div className="validate"></div>
                        </div>
                        <div className="col-lg-6 form-group">
                          <input type="number" className="form-control" name="no_telp" id="no_telp" placeholder="No Telepon" patern="(?=.*[0-9]).{8,15}" required />
                          <div className="validate"></div>
                        </div>
                      </div>
                      
                      <div className="section-title text-left">
                        <h3>User Akun</h3>
                        <hr />
                      </div>
                      <div className="form-row">
                        <div className="col-lg-6 form-group">
                            <input type="text" className="form-control" pattern="[a-zA-Z0-9].{4,25}" name="uname" id="uname" placeholder="Username"  required  />
                            <div className="validate"></div>
                        </div>
                        <div className="col-lg-6 form-group">
                          <input type="email" className="form-control" name="mail" id="mail" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" required />
                          <div className="validate"></div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-lg-6 form-group input-group">
                            <input type="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" className="form-control" name="password" id="password" placeholder="Password"  required  />
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary" type="button" onClick={this.showPass.bind(this)}><i class={this.state.passicon1}></i></button>
                            </div>
                            <div className="validate"></div>
                        </div>
                        <div className="col-lg-6 form-group input-group">
                            <input type="password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" className="form-control" name="retype-password" id="retype-password" placeholder="Retype Password"  required  />
                            <div class="input-group-append">
                              <button class="btn btn-outline-secondary" type="button" onClick={this.showPass2.bind(this)}><i class={this.state.passicon2}></i></button>
                            </div>
                            <div className="validate"></div>
                        </div>
                        <div className="col-lg-12 form-group input-group">*password : terdiri dari kombinasi huruf kapital, huruf kecil, dan angka.</div>
                      </div>
                      <div className="mb-3">
                        <div className="loading">Loading</div>
                        <div className="error-message"></div>
                        <div className="sent-message">Your message has been sent. Thank you!</div>
                      </div>
                      <div className="text-center">
                        <ReCAPTCHA
                          sitekey={RECAPTCHAv2.SITE_KEY}
                          onChange={this.verifyCallback}
                        />
                      </div>
                      <div className="text-center"><button type="submit" id="reg_submit" disabled>Daftar</button></div>
                    </form>
                  </div>
                </div>
                <br/>
              </div>
            </section>
        );
    }
}
export default Register;
