import React from 'react';
import logo from '../logo.svg';
import '../App.css';
import Header from '../common_components/Header';
import api_route from '../common_components/api_route';
import {CryptoJSAesDecrypt} from '../common_components/helper';

const $ = require('jquery');

$.Datatable = require('datatables.net')
class Regulasi extends React.Component{
    
    constructor(props){
		super(props);

		this.state = {
            nama: '',
            data: null
		}

        this.collapsingReg = this.collapsingReg.bind(this);
    }

    componentDidMount(){
        
		$.ajax({
            url: api_route.regulasi,
            type: 'GET',
            success: (response) => {
                // console.log(response)
			  this.setState({
                data : JSON.parse(CryptoJSAesDecrypt(response.data))
              })
            //   console.log("dec", JSON.parse(CryptoJSAesDecrypt(response.data)))
            //   console.log("state", this.state.data)
            //   console.log("length ",this.state.data.length)
            },
            error: (err, response) => {
              alert('connection error');
              if(err.responseJSON){
              	window.location.href = window.location.href.split('#')[0] + '#/';
              }
            }
        });
    }

    collapsingReg(param) {
        if (param == 0) {
            document.getElementById("show_reg").style.display = 'none';
            document.getElementById("hide_reg").style.display = 'block';
        } else {
            document.getElementById("show_reg").style.display = 'block';
            document.getElementById("hide_reg").style.display = 'none';

        }
    }
    render() {
        let data = this.state && this.state.data;
        // console.log("data", data)
        let regulasi_list = [];
        let regulasi_list2 = [];

		if(data != null && data.length > 0){
            let count = 0;
			data.map((value, index) => {
                // console.log(index)
                if(count < 6){
                    regulasi_list.push(
                        <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" key={index}>
                            <span>{('00' + (index+1)).substr(-2)}</span>
                            <p style={{color:'#1f3548', fontWeight:'600', textAlign:'left'}} className="mb-4">{value.keterangan}</p>
                            <a href={api_route.file_path+value.file} target="_blank"><p>lihat</p></a>
                        </div>
                    );
                }else{
                    regulasi_list2.push(
                        <div className="col-lg-4 col-md-6 content-item" data-aos="fade-up" key={index}>
                            <span>{('00' + (index+1)).substr(-2)}</span>
                            <p style={{color:'#1f3548', fontWeight:'600', textAlign:'left'}} className="mb-4">{value.keterangan}</p>
                            <a href={api_route.file_path+value.file} target="_blank"><p>lihat</p></a>
                        </div>
                    );
                }
                count++;
            });
		}
        return (
            <section id="regulasi" className="section-bg about-lists">
                <div className="container">
                    <div className="section-title">
                        <h2>Regulasi</h2>
                    </div>
        
                    <div className="row no-gutters">
                        {regulasi_list}
                        <div className="container text-center" style={{ margin: ' 1px auto' }}>
                            <a data-toggle="collapse" onClick={this.collapsingReg.bind(this, '0')} href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" id="show_reg"><i className="bx bx-chevron-down-circle" style={{ fontSize: '28px', fontWeight: '600',paddingTop : '10px' }}></i></a>
                        </div>
                    </div>
                    <div className="collapse" id="collapseExample">
                        <div className="row no-gutters">
                            {regulasi_list2}
                            <div className="container text-center" style={{ margin: ' 10px auto' }}>
                                <a data-toggle="collapse" onClick={this.collapsingReg.bind(this, '1')} href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" id="hide_reg" style={{ display: 'none' }}><i className="bx bx-chevron-up-circle" style={{ fontSize: '28px', fontWeight: '600' }}></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    
        );
    }
}
export default Regulasi;
